import { GET, PUT, POST, DELETE } from '../utils/request';
import { FORMAT_WITH_HHMMSS, CLIENT_ID, LOYALTY_API_PREFIX, PREPAIDCARD_API_PREFIX } from '../consts';
import moment from 'moment';
import { COMPACT_DATE_FORMAT } from 'src/components/MonthlyStatement/const';

export async function getLoyaltySettings({ restaurant_id }) {
  return GET(
    `${LOYALTY_API_PREFIX}/api/v1/restaurants/${restaurant_id}/restaurant_settings`,
    {},
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function updateLoyaltySettings({ restaurant_id, id, ...settings }) {
  return PUT(`${LOYALTY_API_PREFIX}/api/v1/restaurants/${restaurant_id}/restaurant_settings/${id}`, settings, {
    headers: {
      'client-id': CLIENT_ID,
      'service-region-id': 1,
    },
    parseAsJSONApi: true,
  });
}

export async function getRestaurantLoyaltySummary({ restaurant_id, restaurant_group_id }) {
  let requestUrl = `${LOYALTY_API_PREFIX}/api/v1/restaurants/${restaurant_id}/statistics/summary`;
  let requestParamter = {
    restaurant_id,
  };
  if (restaurant_group_id) {
    requestUrl = `${LOYALTY_API_PREFIX}/api/restaurant_group/v1/statistics/summary`;
    requestParamter['restaurant_group_id'] = restaurant_group_id;
  }
  return GET(
    `${requestUrl}`,
    {
      ...requestParamter,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function getLoyaltySignupSummary({ start_at, end_at, restaurant_id, restaurant_group_id }) {
  let requestUrl = `${LOYALTY_API_PREFIX}/api/v1/restaurants/${restaurant_id}/statistics/signup_summary`;
  let requestParamter = {
    restaurant_id,
  };
  if (restaurant_group_id) {
    requestUrl = `${LOYALTY_API_PREFIX}/api/restaurant_group/v1/statistics/signup_summary`;
    requestParamter['restaurant_group_id'] = restaurant_group_id;
  }
  return GET(
    `${requestUrl}`,
    { start_at, end_at, ...requestParamter },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function getLoyaltyVisitSummary({ start_at, end_at, restaurant_id, restaurant_group_id }) {
  let requestUrl = `${LOYALTY_API_PREFIX}/api/v1/restaurants/${restaurant_id}/statistics/visit_summary`;
  let requestParamter = {
    restaurant_id,
  };
  if (restaurant_group_id) {
    requestUrl = `${LOYALTY_API_PREFIX}/api/restaurant_group/v1/statistics/visit_summary`;
    requestParamter['restaurant_group_id'] = restaurant_group_id;
  }

  return GET(
    `${requestUrl}`,
    { start_at, end_at, ...requestParamter },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function getLoyaltyMemberSummary({ start_at, end_at, restaurant_id, restaurant_group_id }) {
  let requestUrl = `${LOYALTY_API_PREFIX}/api/v1/restaurants/${restaurant_id}/statistics/member_summary`;
  let requestParamter = {
    restaurant_id,
  };
  if (restaurant_group_id) {
    requestUrl = `${LOYALTY_API_PREFIX}/api/restaurant_group/v1/statistics/member_summary`;
    requestParamter['restaurant_group_id'] = restaurant_group_id;
  }

  return GET(
    `${requestUrl}`,
    { start_at, end_at, ...requestParamter },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function getLoyaltyCashbackSummary({ start_at, end_at, restaurant_id, restaurant_group_id }) {
  let requestUrl = `${LOYALTY_API_PREFIX}/api/v1/restaurants/${restaurant_id}/statistics/cashback_summary`;
  let requestParamter = {
    restaurant_id,
  };
  if (restaurant_group_id) {
    requestUrl = `${LOYALTY_API_PREFIX}/api/restaurant_group/v1/statistics/cashback_summary`;
    requestParamter['restaurant_group_id'] = restaurant_group_id;
  }
  return GET(
    `${requestUrl}`,
    { start_at, end_at, ...requestParamter },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function getLoyaltyMemberList({
  restaurant_id,
  page = 1,
  page_size = 10,
  restaurant_group_id = '',
  sort,
  query,
  start_at = '',
  end_at = '',
  time_zone = 'Central Time (US & Canada)',
} = {}) {
  const _FORMAT_WITH_HHMMSS_ = 'YYYY/MM/DDTHH:mm:ss';
  const payload = {
    page,
    per_page: page_size,
    query,
    sort,
  };
  if (start_at && end_at) {
    payload.expiration_date = `${moment(start_at, FORMAT_WITH_HHMMSS).format(_FORMAT_WITH_HHMMSS_)}-${moment(
      end_at,
      FORMAT_WITH_HHMMSS
    ).format(_FORMAT_WITH_HHMMSS_)}-${time_zone}`;
  }
  if (restaurant_group_id) payload.restaurant_group_id = restaurant_group_id;

  return GET(`${LOYALTY_API_PREFIX}/api/v1/restaurants/${restaurant_id}/restaurant_members`, payload, {
    headers: {
      'client-id': CLIENT_ID,
      'service-region-id': 1,
    },
    parseAsJSONApi: true,
  });
}

export async function getPrepaidTransitions({
  restaurant_group_id,
  restaurant_id,
  page = 1,
  page_size = 10,
  query,
  start_at = '',
  end_at = '',
  time_zone = 'Central Time (US & Canada)',
} = {}) {
  const _FORMAT_WITH_HHMMSS_ = 'YYYY/MM/DDTHH:mm:ss';
  return GET(
    `${LOYALTY_API_PREFIX}/api/v1/restaurants/${restaurant_id}/prepaid_cards/purchase_records`,
    {
      page,
      per_page: page_size,
      date: `${moment(start_at, FORMAT_WITH_HHMMSS).format(_FORMAT_WITH_HHMMSS_)}-${moment(
        end_at,
        FORMAT_WITH_HHMMSS
      ).format(_FORMAT_WITH_HHMMSS_)}-${time_zone}`,
      query,
      ...(restaurant_group_id
        ? {
            restaurant_group_id,
          }
        : {}),
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function createMember({
  locale,
  restaurant_id,
  restaurant_group_id,
  restaurant_group_deal_contract_id,
  ...payload
}) {
  let path = `${LOYALTY_API_PREFIX}/api/${locale}/v1/restaurants/${restaurant_id}/restaurant_members`;
  if (restaurant_group_id) {
    path = `${LOYALTY_API_PREFIX}/api/${locale}/restaurant_group/v1/restaurant_group_members`;
  }
  return POST(
    `${path}`,
    {
      ...payload,
      join_member_mode: 'free_join',
      ...(restaurant_group_id
        ? {
            birthday: payload.birthday ? moment(payload.birthday, COMPACT_DATE_FORMAT).format('MM-DD-YYYY') : null,
            expiration_date: payload.expiration_date
              ? moment(payload.expiration_date, COMPACT_DATE_FORMAT).format('MM-DD-YYYY')
              : null,
            restaurant_group_id,
            restaurant_id,
            deal_contract_id: restaurant_group_deal_contract_id,
          }
        : {}),
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function updateMember({ id, locale, restaurant_id, restaurant_group_id, ...payload }) {
  let path = `${LOYALTY_API_PREFIX}/api/${locale}/v1/restaurants/${restaurant_id}/restaurant_members/${id}`;
  return PUT(
    `${path}`,
    {
      ...payload,
      ...(restaurant_group_id
        ? {
            restaurant_group_id,
          }
        : {}),
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function getOrderCashbackDetail({ locale, restaurant_id, order_id }) {
  return POST(
    `${LOYALTY_API_PREFIX}/api/${locale}/v1/restaurants/${restaurant_id}/orders`,
    {
      order_ids: [order_id],
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function getPrepaidCardContract({ restaurant_id }) {
  return GET(
    `${PREPAIDCARD_API_PREFIX}/wallet/api/gift_card/backend/deal_contracts`,
    {
      vendor_id: restaurant_id,
      vendor_type: 'Restaurant',
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
      silence: true,
    }
  );
}

export async function getPrepaidCardPurchaseOptions({ prepaidCardDealContractId }) {
  return GET(
    `${PREPAIDCARD_API_PREFIX}/wallet/api/gift_card/backend/deal_contracts/${prepaidCardDealContractId}/deals`,
    null,
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function toggleActivePrepaidCard({ prepaidCardDealContractId, actived }) {
  return PUT(
    `${PREPAIDCARD_API_PREFIX}/wallet/api/gift_card/backend/deal_contracts/${prepaidCardDealContractId}/active_state`,
    {
      state: actived ? 'active' : 'inactive',
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function removePrepaidCardPurchaseOption({ purchaseOptionId, prepaidCardDealContractId }) {
  return DELETE(
    `${PREPAIDCARD_API_PREFIX}/wallet/api/gift_card/backend/deal_contracts/${prepaidCardDealContractId}/deals/${purchaseOptionId}`,
    {},
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function savePrepaidCardPurchaseOption({
  locale,
  purchaseAmount,
  bonusAmount,
  prepaidCardDealContractId,
}) {
  return POST(
    `${PREPAIDCARD_API_PREFIX}/wallet/api/gift_card/backend/deal_contracts/${prepaidCardDealContractId}/deals`,
    {
      cash_amount: purchaseAmount,
      bonus_amount: bonusAmount,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function updatePrepaidCardPurchaseOption({
  locale,
  purchaseAmount,
  bonusAmount,
  prepaidCardDealContractId,
  purchaseOptionId,
}) {
  return PUT(
    `${PREPAIDCARD_API_PREFIX}/wallet/api/gift_card/backend/deal_contracts/${prepaidCardDealContractId}/deals/${purchaseOptionId}`,
    {
      cash_amount: purchaseAmount,
      bonus_amount: bonusAmount,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function getRestaurantPrepaidCardSummary({ restaurant_id, restaurant_group_id }) {
  let requestUrl = `${PREPAIDCARD_API_PREFIX}/wallet/api/gift_card/backend/statistics/card_summary`;
  let requestParamter = {
    vendor_id: restaurant_id,
    vendor_type: 'Restaurant',
    restaurant_id,
  };
  if (restaurant_group_id) {
    requestUrl = `${PREPAIDCARD_API_PREFIX}/wallet/api/gift_card/backend/statistics/card_summary`;
    requestParamter['vendor_id'] = restaurant_group_id;
    requestParamter['vendor_type'] = 'RestaurantGroup';
  }

  return GET(
    `${requestUrl}`,
    {
      ...requestParamter,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function getLoyaltyMemberTransactions({ memberId, restaurantId, page, pageSize, restaurantGroupId }) {
  return GET(
    `${LOYALTY_API_PREFIX}/api/v2/restaurants/${restaurantId}/restaurant_members/${memberId}/orders`,
    {
      page,
      page_size: pageSize,
      ...(restaurantGroupId
        ? {
            restaurant_group_id: restaurantGroupId,
          }
        : {}),
    },
    {
      parseAsJSONApi: true,
    }
  );
}

export async function getLoyaltyPointsHistory({
  restaurant_group_id,
  restaurant_id,
  page = 1,
  page_size = 10,
  query,
  start_at = '',
  end_at = '',
  action_type = '', //consume/accumulate/all
  time_zone = 'Central Time (US & Canada)',
} = {}) {
  const _FORMAT_WITH_HHMMSS_ = 'YYYY/MM/DDTHH:mm:ss';
  return GET(
    `${LOYALTY_API_PREFIX}/api/points/v1/member_points`,
    {
      page,
      per_page: page_size,
      action_type,
      ...(restaurant_id
        ? {
            restaurant_id,
          }
        : {}),
      date: `${moment(start_at, FORMAT_WITH_HHMMSS).format(_FORMAT_WITH_HHMMSS_)}-${moment(
        end_at,
        FORMAT_WITH_HHMMSS
      ).format(_FORMAT_WITH_HHMMSS_)}-${time_zone}`,
      query,
      ...(restaurant_group_id
        ? {
            restaurant_group_id,
          }
        : {}),
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function getLoyaltyPointsSummary({
  restaurant_group_id,
  restaurant_id,
  start_at = '',
  end_at = '',
  time_zone = 'Central Time (US & Canada)',
} = {}) {
  const _FORMAT_WITH_HHMMSS_ = 'YYYY/MM/DDTHH:mm:ss';
  return GET(
    `${LOYALTY_API_PREFIX}/api/points/v1/member_points/summary`,
    {
      ...(restaurant_id
        ? {
            restaurant_id,
          }
        : {}),
      date: `${moment(start_at, FORMAT_WITH_HHMMSS).format(_FORMAT_WITH_HHMMSS_)}-${moment(
        end_at,
        FORMAT_WITH_HHMMSS
      ).format(_FORMAT_WITH_HHMMSS_)}-${time_zone}`,
      ...(restaurant_group_id
        ? {
            restaurant_group_id,
          }
        : {}),
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: false,
    }
  );
}

//获取不累计积分的meal和category
export async function getMealAndCategoryBlacklistForPoints({ restaurant_id }) {
  return GET(
    `${LOYALTY_API_PREFIX}/api/points/v1/meal_and_category_blacklist`,
    {
      restaurant_id,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export async function updateMealAndCategoryBlacklistForPoints({ restaurant_id, categories, meals }) {
  return POST(
    `${LOYALTY_API_PREFIX}/api/points/v1/meal_and_category_blacklist`,
    {
      restaurant_id,
      categories,
      meals,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}
