export default {
  //revenueSummary,
  'salesSummary.revenueSummary.title': 'Sales Summary',
  'salesSummary.revenueSummary.net_sales': 'Net Sales',
  'salesSummary.revenueSummary.tax_amount': 'Tax Amount',
  'salesSummary.revenueSummary.grand_total': 'Grand Total',
  'salesSummary.revenueSummary.fee_amount': 'Fee Amount',
  'salesSummary.revenueSummary.tips': 'Tips',
  'salesSummary.revenueSummary.service_tips': 'Service Tips',
  'salesSummary.revenueSummary.delivery_tips': 'Delivery Tips',
  'salesSummary.revenueSummary.comps': 'Comps',
  'salesSummary.revenueSummary.deferred_sales': 'Deferred Sales',
  'salesSummary.revenueSummary.sales_revenue': 'Sales Revenue',
  'salesSummary.revenueSummary.excess_payments': 'Excess Payments',
  'salesSummary.revenueSummary.total_revenue': 'Total Amount',

  //orderSummary,
  'salesSummary.orderSummary.title': 'Order Summary',
  'salesSummary.orderSummary.closed_orders': 'Closed Orders',
  'salesSummary.orderSummary.open_orders': 'Open Orders',
  'salesSummary.orderSummary.canceled_orders': 'Canceled Orders',

  //netSalesSummary,
  'salesSummary.netSalesSummary.title': 'Net Sales Summary',
  'salesSummary.netSalesSummary.gross_sales': 'Gross Sales',
  'salesSummary.netSalesSummary.discounts': 'Discounts',
  'salesSummary.netSalesSummary.refunds': 'Refunds',
  'salesSummary.netSalesSummary.net_sales': 'Net Sales',

  //paymentsSummary,
  'salesSummary.paymentsSummary.title': 'Payments Summary',
  'salesSummary.paymentsSummary.card': 'Card',
  'salesSummary.paymentsSummary.amex': 'Amex',
  'salesSummary.paymentsSummary.discover': 'Discover',
  'salesSummary.paymentsSummary.mastercard': 'Mastercard',
  'salesSummary.paymentsSummary.visa': 'Visa',
  'salesSummary.paymentsSummary.cash': 'Cash',
  'salesSummary.paymentsSummary.online': 'Online',
  'salesSummary.paymentsSummary.other': 'Other',
  'salesSummary.paymentsSummary.loyalty': 'Loyalty',
  'salesSummary.paymentsSummary.gift_card': 'Gift Card',
  'salesSummary.paymentsSummary.total': 'Paid Total',

  'salesSummary.paymentsSummary.columns.pmt_method': 'PMT METHOD',
  'salesSummary.paymentsSummary.columns.count': 'COUNT',
  'salesSummary.paymentsSummary.columns.amount': 'AMOUNT',
  'salesSummary.paymentsSummary.columns.tips': 'TIPS',
  'salesSummary.paymentsSummary.columns.non-tips': 'NON-TIPS',
  'salesSummary.paymentsSummary.columns.refunds': 'REFUNDS',
  'salesSummary.paymentsSummary.columns.gross_amount': 'GROSS AMOUNT',

  //tipsSummary,
  'salesSummary.tipsSummary.title': 'Tips Summary',
  'salesSummary.tipsSummary.card': 'Card',
  'salesSummary.tipsSummary.cash': 'Cash',
  'salesSummary.tipsSummary.other': 'Other',
  'salesSummary.tipsSummary.total': 'Total',

  'salesSummary.tipsSummary.columns.pmt_method': 'PMT METHOD',
  'salesSummary.tipsSummary.columns.service_tips': 'SERVICE TIPS',
  'salesSummary.tipsSummary.columns.delivery_tips': 'DELIVERY TIPS',

  // discountSummary,
  'salesSummary.discountSummary.title': 'Discount Summary',
  'salesSummary.discountSummary.one-off_discount': 'One-Off Discount',
  'salesSummary.discountSummary.loyalty': 'Loyalty',
  'salesSummary.discountSummary.promotion': 'Promotion',
  'salesSummary.discountSummary.total': 'Total',

  'salesSummary.discountSummary.columns.discount': 'DISCOUNT',
  'salesSummary.discountSummary.columns.count': 'COUNT',
  'salesSummary.discountSummary.columns.amount': 'AMOUNT',

  // breakdownOfGratuityAndTips,
  'salesSummary.breakdownOfGratuityAndTips.title': 'Breakdown of Gratuity and Tips',
  'salesSummary.breakdownOfGratuityAndTips.gratuity': 'Gratuity',
  'salesSummary.breakdownOfGratuityAndTips.card': 'Card',
  'salesSummary.breakdownOfGratuityAndTips.cash': 'Cash',
  'salesSummary.breakdownOfGratuityAndTips.other': 'Other',
  'salesSummary.breakdownOfGratuityAndTips.tips': 'Tips',
  'salesSummary.breakdownOfGratuityAndTips.total': 'Total',

  'salesSummary.breakdownOfGratuityAndTips.columns.type': 'TYPE',
  'salesSummary.breakdownOfGratuityAndTips.columns.service_tips': 'SERVICE TIPS',
  'salesSummary.breakdownOfGratuityAndTips.columns.delivery_tips': 'DELIVERY TIPS',

  // feesSummary,
  'salesSummary.feesSummary.title': 'Fees Summary',
  'salesSummary.feesSummary.non-taxable_fees': 'Non-taxable Fees',
  'salesSummary.feesSummary.18%_gratuity': '18% Gratuity',
  'salesSummary.feesSummary.cc_fee': 'CC Fee',
  'salesSummary.feesSummary.taxable_fees': 'Taxable-fees',
  'salesSummary.feesSummary.delivery_fee': 'Delivery Fee',
  'salesSummary.feesSummary.package_fee': 'package Fee',
  'salesSummary.feesSummary.total': 'Total',

  'salesSummary.feesSummary.columns.fee': 'FEE',
  'salesSummary.feesSummary.columns.amount': 'AMOUNT',

  // employeeTipsAccount,
  'salesSummary.employeeTipsAccount.title': 'Employee Tips Account',
  'salesSummary.employeeTipsAccount.total_service_tips': 'Total Service Tips',
  'salesSummary.employeeTipsAccount.tip_out': 'Tip Out',
  'salesSummary.employeeTipsAccount.tips_owed_to_employee': 'Tips Owed To Employee',

  // deferredSalesSummary,
  'salesSummary.deferredSalesSummary.title': 'Deferred Sales Summary',
  'salesSummary.deferredSalesSummary.gift_card': 'Gift Card',
  'salesSummary.deferredSalesSummary.cash': 'Cash',
  'salesSummary.deferredSalesSummary.other': 'Other',
  'salesSummary.deferredSalesSummary.loyalty_prepaid': 'Loyalty Prepaid',
  'salesSummary.deferredSalesSummary.card': 'Card',
  'salesSummary.deferredSalesSummary.annual_mem._fee': 'Annual Mem. Fee',
  'salesSummary.deferredSalesSummary.total': 'Total',

  'salesSummary.deferredSalesSummary.columns.type': 'TYPE',
  'salesSummary.deferredSalesSummary.columns.count': 'COUNT',
  'salesSummary.deferredSalesSummary.columns.amount': 'AMOUNT',

  // voidSummary,
  'salesSummary.voidSummary.title': 'Void Summary',
  'salesSummary.voidSummary.void_amount': 'Void Amount',
  'salesSummary.voidSummary.void_oder_count': 'Void Oder Count',
  'salesSummary.voidSummary.void_item_count': 'Void Item Count',

  // cashDrawerExpectedBalance,
  'salesSummary.cashDrawerExpectedBalance.title': 'Cash Drawer Expected Balance',
  'salesSummary.cashDrawerExpectedBalance.cash_sales': 'Cash Sales',
  'salesSummary.cashDrawerExpectedBalance.cash_tips': 'Cash Tips',
  'salesSummary.cashDrawerExpectedBalance.miscellaneous_cash': 'Misc. Cash',
  'salesSummary.cashDrawerExpectedBalance.starting_balance': 'Starting Balance',
  'salesSummary.cashDrawerExpectedBalance.cash_in_other': 'Other',
  'salesSummary.cashDrawerExpectedBalance.total_cash_in': 'Cash In Balance',
  'salesSummary.cashDrawerExpectedBalance.cash_refund': 'Cash Refund',
  'salesSummary.cashDrawerExpectedBalance.employee_reimbursement': 'Employee Reimbursement',
  'salesSummary.cashDrawerExpectedBalance.cash_out_other': 'Other',
  'salesSummary.cashDrawerExpectedBalance.safe_drop': 'Safe Drop',
  'salesSummary.cashDrawerExpectedBalance.tips_payout': 'Tips Payout',
  'salesSummary.cashDrawerExpectedBalance.total_cash_out': 'Cash Out Balance',
  'salesSummary.cashDrawerExpectedBalance.balance': 'Balance',

  // refundsSummary,
  'salesSummary.refundsSummary.title': 'Refunds Summary',
  'salesSummary.refundsSummary.sales_refunds': 'Sales Refunds',
  'salesSummary.refundsSummary.tax_refunds': 'Tax Refunds',
  'salesSummary.refundsSummary.fee_refunds': 'Fee Refunds',
  'salesSummary.refundsSummary.tips_refunds': 'Tips Refunds',
  'salesSummary.refundsSummary.reopen_refunds': 'Reopen Refunds',
  'salesSummary.refundsSummary.payment_refunds': 'Payment Refunds',
  'salesSummary.refundsSummary.total_refunds': 'Total Refunds',

  // dineInGuests,
  'salesSummary.dineInGuests.title': 'Dine-In Guests',
  'salesSummary.dineInGuests.columns.order_source': 'ORDER SOURCE',
  'salesSummary.dineInGuests.columns.guests': 'GUESTS',

  // houseCash,
  'salesSummary.houseCash.title': 'House Cash',
  'salesSummary.houseCash.cash_total_in_hand': 'Cash Total In Hand',
  'salesSummary.houseCash.non-delivery': 'Non-Delivery',
  'salesSummary.houseCash.delivery': 'Delivery',
  'salesSummary.houseCash.in-house_cash_service_tips': 'In-House Cash Service Tips',
  'salesSummary.houseCash.in-house_card_service_tips': 'In-House Card Service Tips',
  'salesSummary.houseCash.in-house_other_service_tips': 'In-House Other Service Tips',
  'salesSummary.houseCash.in-house_card_service_tips_withholding': 'In-House Card Service Tips Withholding',
  'salesSummary.houseCash.in-house_net_sales_withholding': 'In-House Net Sales Withholding',
  'salesSummary.houseCash.online_service_tips': 'Online Service Tips',
  'salesSummary.houseCash.online_service_tips_withholding': 'Online Service Tips Withholding',
  'salesSummary.houseCash.in-house_cash_delivery_tips': 'In-House Cash Delivery Tips',
  'salesSummary.houseCash.in-house_card_delivery_tips': 'In-House Card DeliveryTips',
  'salesSummary.houseCash.in-house_other_delivery_tips': 'In-House Other Delivery Tips',
  'salesSummary.houseCash.in-house_delivery_fee': 'In-House Delivery Fee',
  'salesSummary.houseCash.in-house_card_delivery_tips_withholding': 'In-House Card Delivery Tips Withholding',
  'salesSummary.houseCash.online_delivery_tips': 'Online Delivery Tips',
  'salesSummary.houseCash.online_delivery_fee': 'Online Delivery Fee',
  'salesSummary.houseCash.online_delivery_tips_withholding': 'Online Delivery Tips Withholding',
  'salesSummary.houseCash.cash_owed_to_house': 'Cash Owed To House',

  // netSalesMetrics,
  'salesSummary.netSalesMetrics.title': 'Net Sales Metrics',
  'salesSummary.netSalesMetrics.total_orders': 'Total Orders',
  'salesSummary.netSalesMetrics.net_sales_total_orders': 'Net Sales/Total Orders',
  'salesSummary.netSalesMetrics.total_items': 'Total Items',
  'salesSummary.netSalesMetrics.net_sales_total_items': 'Net Sales/Total Items',
  'salesSummary.netSalesMetrics.dine-in_guests': 'Dine-In Guests',
  'salesSummary.netSalesMetrics.net_sales_total_guests': 'Net Sales/Total Guests',

  // orderSourcesSummary,
  'salesSummary.orderSourcesSummary.title': 'Sales Revenue by Order Sources',
  'salesSummary.orderSourcesSummary.total': 'Total',

  'salesSummary.orderSourcesSummary.columns.source': 'SOURCE',
  'salesSummary.orderSourcesSummary.columns.orders': 'ORDERS',
  'salesSummary.orderSourcesSummary.columns.net_sales': 'NET SALES',
  'salesSummary.orderSourcesSummary.columns.discounts': 'DISCOUNTS',
  'salesSummary.orderSourcesSummary.columns.service_tips': 'SERVICE TIPS',
  'salesSummary.orderSourcesSummary.columns.delivery_tips': 'DELIVERY TIPS',
  'salesSummary.orderSourcesSummary.columns.revenue': 'SALES REVENUE',

  // onlinePlatformsSummary,
  'salesSummary.onlinePlatformsSummary.title': 'Sales Revenue by Online Platforms',

  'salesSummary.onlinePlatformsSummary.columns.platform': 'PLATFORM',
  'salesSummary.onlinePlatformsSummary.columns.orders': 'ORDERS',
  'salesSummary.onlinePlatformsSummary.columns.net_sales': 'NET SALES',
  'salesSummary.onlinePlatformsSummary.columns.discounts': 'DISCOUNTS',
  'salesSummary.onlinePlatformsSummary.columns.service_tips': 'SERVICE TIPS',
  'salesSummary.onlinePlatformsSummary.columns.delivery_tips': 'DELIVERY TIPS',
  'salesSummary.onlinePlatformsSummary.columns.revenue': ' SALES REVENUE',

  // serviceTypesSummary,
  'salesSummary.serviceTypesSummary.title': 'Sales Revenue by Service Types',
  'salesSummary.serviceTypesSummary.total': 'Total',

  'salesSummary.serviceTypesSummary.columns.type': 'TYPE',
  'salesSummary.serviceTypesSummary.columns.orders': 'ORDERS',
  'salesSummary.serviceTypesSummary.columns.net_sales': 'NET SALES',
  'salesSummary.serviceTypesSummary.columns.discounts': 'DISCOUNTS',
  'salesSummary.serviceTypesSummary.columns.service_tips': 'SERVICE TIPS',
  'salesSummary.serviceTypesSummary.columns.delivery_tips': 'DELIVERY TIPS',
  'salesSummary.serviceTypesSummary.columns.revenue': 'SALES REVENUE',

  //order sources
  'salesSummary.orderSources.walk_in': 'Walk In',
  'salesSummary.orderSources.call_in': 'Call In',
  'salesSummary.orderSources.qr_code': 'QR Code',
  'salesSummary.orderSources.kiosk': 'Kiosk',
  'salesSummary.orderSources.terminal': 'Terminal',
  'salesSummary.orderSources.external': 'External',
  'salesSummary.orderSources.loyalty': 'Loyalty',
  'salesSummary.orderSources.online': 'Online',

  'salesSummary.orderSources.total': 'Total',

  //service type
  'salesSummary.serviceType.dine_in': 'Dine In',
  'salesSummary.serviceType.take_out': 'Take Out',
  'salesSummary.serviceType.delivery': 'Delivery',
  'salesSummary.serviceType.other': 'Other',
  'salesSummary.serviceType.total': 'Total',

  'salesSummary.timeWarning': 'Select a date range not exceeding one month.',

  //section sales
  'sectionSales.table.title': 'Section Sales',
  'sectionSales.columns.name': 'Name',
  'sectionSales.columns.order_count': 'Orders',
  'sectionSales.columns.gross_sales': 'Gross Sales',
  'sectionSales.columns.discounts': 'Discounts',
  'sectionSales.columns.net_sales': 'Net Sales',
  'sectionSales.columns.taxes': 'Tax Amount',
  'sectionSales.columns.service_tips': 'Tips',

  'sectionSales.summary.total': 'Sales Summary',
};
