import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { getLocalItem, getSessionItem, KEYS, USER_KEYS } from 'src/store/storage';
import { DISTRIBUTION_MODES, LOCALE_EN } from 'src/consts';
import { IDENTITY_ADMIN } from 'src/consts/user';
import { DEFAULT_CUTOFF_TIME } from 'src/consts/enum';
const Decimal = require('decimal.js');

const IMAGE_PARALLEL_NUMBER = 4;
export const DEFAULT_TIME_ZONE = 'America/Chicago';
const MONTHLY_REPORT_GENERATOR_FLAG = 'RestaurantReportGenerator';
const LOCALE_ZH = 'zh-Hans';

export function getUserInfo() {
  let userInfo;

  try {
    userInfo = JSON.parse(getSessionItem(KEYS.userInfo || '{}')) || {};
  } catch (e) {
    userInfo = {};
  }

  return userInfo;
}

export function downloadFile(blob, fileName) {
  const csvUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = fileName;
  link.style.display = 'none';
  link.href = csvUrl;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * 判断element的父元素中是否包含某class
 * @param dom 元素
 * @param classes 样式类名(数组/字符串)
 */
export function domParentHasClass(dom, classes) {
  let node = dom;

  let classNames;

  if ({}.toString.call(classes) === '[object Array]') {
    classNames = classes;
  } else {
    classNames = [classes];
  }

  while (node) {
    let klass = node.className;

    if (typeof klass !== 'string') klass = '';

    if (node && !!classNames.find((_) => klass.includes(_))) {
      return true;
    }

    node = node.parentNode;
  }

  return false;
}

export function getItemName(item, locale = LOCALE_EN, { nameField = 'name', foreignNameField = 'foreign_name' } = {}) {
  if (!item) return '';
  if (locale === LOCALE_EN) return item[nameField];
  return item[foreignNameField] || item[nameField] || '';
}

const unitSteps = [
  { mod: 1000 * 1000 * 1000, unitAbbr: 'b' },
  { mod: 1000 * 1000, unitAbbr: 'm' },
  { mod: 1000, unitAbbr: 'k' },
];

export function formatCurrency(
  currency,
  {
    unit = '$',
    div100 = true,
    defaultValue = '$0.00',
    precision = 2,
    seperator = ',',
    abbr = false,
    bracket = false,
  } = {}
) {
  if (isNaN(currency)) return defaultValue;
  let amount = Math.abs(currency);
  if (div100) amount = amount / 100;
  let formattedCurrency;
  if (abbr) {
    for (let i = 0; i < unitSteps.length; i++) {
      const { mod, unitAbbr } = unitSteps[i];
      if (amount >= mod) {
        formattedCurrency = `${(amount / mod).toFixed(precision)}${unitAbbr}`;
        break;
      }
    }

    if (!formattedCurrency) formattedCurrency = amount.toFixed(precision);
  } else {
    formattedCurrency = formatNumber(amount, { precision, seperator });
  }

  let formattedText = unit + formattedCurrency;

  if (bracket && currency < 0) return `(${formattedText})`;
  if (!bracket && currency < 0) return `-${formattedText}`;
  return formattedText;
}

export function formatNumber(number, { precision = 0, seperator = ',' } = {}) {
  if (isNaN(number)) return number;
  const fixedNumber = String(Number(number).toFixed(precision));
  const [integer, decimal] = fixedNumber.split('.');
  let formattedText = integer.replace(/\B(?=(\d{3})+(?!\d))/g, seperator);
  if (decimal) formattedText += `.${decimal}`;
  return formattedText;
}

export function getRestaurantTimeZone() {
  return getSessionItem(USER_KEYS.zoneInfo) || DEFAULT_TIME_ZONE;
}

export function transferZoneToIANA(timeZone) {
  return timeZone || getRestaurantTimeZone();
}

/***
 * 按指定格式显示指定时区的日期
 * @param {Number|Date} timestamp 时间戳/Date
 * @param {Object} options 可选参数
 * @param {String} options.format 日期显示格式，默认为MM/DD/YYYY
 * @param {String} options.timeZone 餐厅时区，不传是取餐厅所在时区，获取餐厅时区失败时默认为America/Chicage
 */
export function formatTimeInSpecificZone(timestamp, { format = 'MM/DD/YYYY', timeZone } = {}) {
  try {
    const date = convertTimestampToZonedTime(timestamp, timeZone);
    return date.format(format);
  } catch (e) {
    return null;
  }
}

/**
 * type DateArray = [year, month, day, hour, minute, second, millisecond]
 * 将iso8601/时间戳/Date/DateArray日期转成指定时区的moment对象
 * @param {String|Number|Date} timestamp 待转换的日期
 * @param {String} timeZone 指定的时区
 * @returns moment
 */
export function convertTimestampToZonedTime(timestamp, timeZone) {
  try {
    return momentTimezone.tz(timestamp, transferZoneToIANA(timeZone));
  } catch (e) {
    return moment();
  }
}

export function getPeriodName(hour) {
  if (hour >= 21 || hour < 5) return 'Night';
  if (hour < 12) return 'Morning';
  if (hour < 17) return 'Afternoon';
  return 'Evening';
}

/**
 * appInfo format
 *  @param platform string "iOS"/"Android"/"Web"
 *  @param application string "pos"/"restaurant"/"report"
 *  @param version string
 *  @param token string
 *  @param refreshToken string
 *  @param lang string "en"/"zh-Hans"
 */
export function getAppInfos() {
  if (typeof window === 'undefined') return { isApp: false, isMonthlyReport: false };
  const userAgent = navigator.userAgent;
  if (userAgent.includes(MONTHLY_REPORT_GENERATOR_FLAG)) return { isApp: false, isMonthlyReport: true };
  if (window.userInfo) {
    let userInfo = window.userInfo;
    const type = Object.prototype.toString.call(userInfo);
    if (type === '[object String]') {
      try {
        userInfo = JSON.parse(userInfo);
      } catch (e) {
        userInfo = {};
      }
    }

    if (!userInfo.lang) userInfo.lang = LOCALE_EN;
    if (userInfo.lang === LOCALE_ZH) userInfo.lang = 'zh';

    return { isApp: true, isMonthlyReport: false, ...userInfo };
  }

  return { isApp: false, isMonthlyReport: false };
}

export function capitalizeAllWords(text, split = '_') {
  if (!text || {}.toString.call(text) !== '[object String]') return text;
  return text
    .split(split)
    .map((word) => {
      return word.substring(0, 1).toUpperCase() + word.substring(1);
    })
    .join(' ');
}

export function clearSelections() {
  let selection;

  if (window.getSelection) {
    selection = window.getSelection();
    if (selection.empty) {
      selection.empty();
    } else if (selection.removeAllRanges) {
      selection.removeAllRanges();
    }
    return;
  }

  if (document.getSelection) {
    selection = document.getSelection();
    if (selection.empty) {
      selection.empty();
    }
  }
}

export function loadImage(imageUrl) {
  if (!imageUrl) {
    return Promise.resolve(null);
  }
  return new Promise((resolve) => {
    const image = new Image();
    if (imageUrl.includes('http')) {
      image.setAttribute('crossOrigin', 'Anonymous');
    }

    image.onload = () => resolve(image);
    image.onerror = () => resolve(null);
    image.src = imageUrl;
  });
}

export async function getBase64ImageFromUrl(imageUrl) {
  if (!imageUrl) return '';
  if (imageUrl.includes('http') && location.hostname.includes('localhost')) return '';
  const img = await loadImage(imageUrl);
  if (!img) return null;
  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0, img.width, img.height);
  return canvas.toDataURL('image/png');
}

const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIGKLMNOPQRSTUVWXYZ'.split('');

export function generateShortUUID(len = 6) {
  const rawUUID = uuidv4().replace(/-/g, '');
  let shortUUID = '';

  const step = Math.ceil(rawUUID.length / len);
  for (let i = 0; i < len; i++) {
    let str = rawUUID.substring(i * step, (i + 1) * step);
    const x = parseInt(str, 16);
    shortUUID += chars[x % 0x3e];
  }
  return shortUUID;
}

export function padZero(val = 0, length = 2) {
  const strVal = String(val);
  const len = strVal.length;
  if (len >= length) return strVal;
  const diff = length - len;
  const pad = Array(diff).fill('0').join('');
  return pad + strVal;
}

export function formatDuration(seconds = 0) {
  const mins = parseInt(seconds / 60);
  const secs = seconds % 60;
  return padZero(mins, 2) + ':' + padZero(secs, 2);
}

export function escapeInputText(text) {
  if (!text) return text;
  return text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
}

export function unescapeInputText(text) {
  if (!text) return text;
  return text.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
}

export function getIntervalDays(startAt, endAt) {
  if (!startAt || !endAt) return 0;

  let range = endAt.diff(startAt) / (3600 * 1000);

  if (range === 0) return 0;

  if (!endAt.isDST() && startAt.isDST()) {
    /**
     * startDate是夏令时，endDate不是夏令时，会导致两者的时间间隔多1小时,计算间隔天数时先减去1h
     * 由于startAt, endAt改变 getIntervalDays会被多次调用，直接操作startAt, endAt会导致数据差距越来越大
     * 因此不操作startAt, endAt，而是range减去1
     */
    range = range - 1;
  }
  return Math.ceil(range / 24);
}

export function getTodayMoment(reportCutoffTime) {
  let today = momentTimezone.tz(moment().valueOf(), transferZoneToIANA());
  const time = today.format('HH:mm:ss');

  if (reportCutoffTime && isCutoffTimeCrossDays(reportCutoffTime) && time < reportCutoffTime) {
    today.subtract(1, 'd');
  }
  return today;
}

export function getLocalConfigByKey(key) {
  let value = getLocalItem(key);
  if (!value) return null;

  try {
    value = JSON.parse(value);
  } catch (e) {
    value = null;
  }

  return value;
}

/**
 * checkIfOwnCertainContract 检查餐厅是否开启了某个合约
 * @param {object} restaurantInfo
 * @param {string} distributionMode 合约distributionMode
 * @returns boolean
 */
export function checkIfOwnCertainContract(restaurantInfo = {}, distributionMode) {
  const { restaurant_contracts } = restaurantInfo;
  return !!(restaurant_contracts || []).find((_) => _.distribution_mode === distributionMode);
}

export function checkIfOwnPOSContract(restaurantInfo = {}) {
  return checkIfOwnCertainContract(restaurantInfo, DISTRIBUTION_MODES.POS);
}

export function checkIfOwnWebsiteContract(restaurantInfo = {}) {
  return checkIfOwnCertainContract(restaurantInfo, DISTRIBUTION_MODES.WHITE_LABEL);
}

export function checkIfOwnLoyaltyContract(restaurantInfo = {}) {
  return checkIfOwnCertainContract(restaurantInfo, DISTRIBUTION_MODES.LOYALTY);
}

export function checkIfOwnLoyaltyCRMContract(restaurantInfo = {}) {
  return checkIfOwnCertainContract(restaurantInfo, DISTRIBUTION_MODES.LOYALTY_CRM);
}

export function shouldValidatePermission(restaurantInfo) {
  const identity = getSessionItem(KEYS.identity);
  if (identity === IDENTITY_ADMIN) return true;
  const hasPOSContract = checkIfOwnPOSContract(restaurantInfo);
  if (!hasPOSContract) return false;
  const activated = getSessionItem(KEYS.activated);
  const ownerId = getSessionItem(KEYS.ownerId);
  return activated || !!ownerId;
}

export function getRestaurantAddresses(address) {
  if (!address) return null;
  const { address_1, address_2, city, state, zip_code } = address;
  const address1 = `${address_1 || ''} ${address_2 || ''}`.replace(/\s+/g, ' ');
  const address2 = `${city || ''} ${state || ''} ${zip_code || ''}`.replace(/\s+/g, ' ');

  return {
    address1,
    address2,
  };
}

async function generatePDFImage(childNode, html2canvas) {
  let image;

  try {
    const canvas = await html2canvas(childNode, {
      scale: 2,
    });
    const base64ImageUrl = canvas.toDataURL();
    image = await loadImage(base64ImageUrl);
  } catch (e) {
    image = null;
  }

  return image;
}

export async function generatePDFImages(childNodes) {
  const html2canvasModule = await import('html2canvas' /* webpackChunkName:"pdf" */);
  const html2canvas = html2canvasModule.default;
  const children = Array.from(childNodes);
  const times = Math.ceil(children.length / IMAGE_PARALLEL_NUMBER);
  const elementList = [];
  for (let i = 0; i < times; i++) {
    const slice = children.slice(i * IMAGE_PARALLEL_NUMBER, (i + 1) * IMAGE_PARALLEL_NUMBER);
    elementList.push(slice);
  }

  const allImages = [];
  for await (let elements of elementList) {
    const images = await Promise.all(elements.map((childNode) => generatePDFImage(childNode, html2canvas)));
    allImages.push(...images);
  }

  return allImages;
}

export function digestCutoffTime(cutoffTime) {
  return (cutoffTime || DEFAULT_CUTOFF_TIME).split(':');
}

export function getDateTimeByCutoffTime(momentDate, cutoffTime) {
  const [hour, minute, seconds] = digestCutoffTime(cutoffTime);
  return moment(momentDate)
    .hour(+hour)
    .minute(+minute)
    .seconds(+seconds);
}

export function getWithinSameDayTimeRangeByValue(val) {
  const today = getTodayMoment();
  let startAt;
  let endAt;

  switch (val) {
    case 'today': {
      startAt = today.clone();
      endAt = today.clone();
      break;
    }

    case 'yesterday': {
      startAt = today.clone().subtract(1, 'd');
      endAt = startAt.clone();
      break;
    }

    case 'last7D': {
      startAt = today.clone().subtract(6, 'd').startOf('d');
      endAt = today.clone();
      break;
    }

    case 'last14D': {
      startAt = today.clone().subtract(13, 'd').startOf('d');
      endAt = today.clone();
      break;
    }

    case 'thisWeek': {
      startAt = today.clone().startOf('week');
      endAt = today.clone().endOf('week');
      break;
    }

    case 'lastWeek': {
      const dayOfLastWeek = today.clone().subtract(7, 'd');
      startAt = dayOfLastWeek.clone().startOf('week');
      endAt = dayOfLastWeek.clone().endOf('week');
      break;
    }

    case 'thisMonth': {
      startAt = today.clone().startOf('month');
      endAt = today.clone().endOf('month');
      break;
    }

    case 'lastMonth': {
      const dayOfLastMonth = today.clone().subtract(1, 'month');
      startAt = dayOfLastMonth.clone().startOf('month');
      endAt = dayOfLastMonth.clone().endOf('month');
      break;
    }

    case 'twoMonthsAgo': {
      const dayOfTwoMonthsAgo = today.clone().subtract(2, 'month');
      startAt = dayOfTwoMonthsAgo.clone().startOf('month');
      endAt = dayOfTwoMonthsAgo.clone().endOf('month');
      break;
    }

    case 'last30D': {
      startAt = today.clone().subtract(29, 'd');
      endAt = today.clone();
      break;
    }

    case 'last90D': {
      startAt = today.clone().subtract(89, 'd');
      endAt = today.clone();
      break;
    }

    default: {
      startAt = today.clone();
      endAt = today.clone();
    }
  }

  startAt.hour(0).minute(0).second(0);
  endAt.hour(23).minute(59).second(59);

  return {
    startAt,
    endAt,
  };
}

function getCrossDaysTimeRangeByValue(val, cutoffTime, withTime = true) {
  const today = getTodayMoment(cutoffTime);
  let startAt;
  let endAt;

  switch (val) {
    case 'today': {
      startAt = today.clone();
      endAt = today.clone();
      break;
    }

    case 'yesterday': {
      startAt = today.clone().subtract(1, 'd');
      endAt = startAt.clone();
      break;
    }

    case 'last7D': {
      startAt = today.clone().subtract(6, 'd').startOf('d');
      endAt = today.clone();
      break;
    }

    case 'last14D': {
      startAt = today.clone().subtract(13, 'd').startOf('d');
      endAt = today.clone();
      break;
    }

    case 'last30D': {
      startAt = today.clone().subtract(29, 'd');
      endAt = today.clone();
      break;
    }

    case 'last90D': {
      startAt = today.clone().subtract(89, 'd');
      endAt = today.clone();
      break;
    }

    case 'thisWeek': {
      startAt = today.clone().startOf('week');
      endAt = startAt.clone().endOf('week');
      break;
    }

    case 'lastWeek': {
      const dayOfLastWeek = today.clone().subtract(7, 'd');
      startAt = dayOfLastWeek.clone().startOf('week');
      endAt = startAt.clone().endOf('week');
      break;
    }

    case 'thisMonth': {
      startAt = today.clone().startOf('month');
      endAt = startAt.clone().endOf('month');
      break;
    }

    case 'lastMonth': {
      startAt = today.clone().subtract(1, 'month').startOf('month');
      endAt = startAt.clone().endOf('month');
      break;
    }

    default: {
      startAt = today.clone();
      endAt = today.clone();
    }
  }

  if (withTime) {
    endAt = endAt.clone().add(1, 'd');
  }

  return {
    startAt: withTime ? getDateTimeByCutoffTime(startAt, cutoffTime) : startAt,
    endAt: withTime ? getDateTimeByCutoffTime(endAt, cutoffTime) : endAt,
  };
}

export function isCutoffTimeCrossDays(cutoffTime) {
  const time = cutoffTime || DEFAULT_CUTOFF_TIME;
  return time > DEFAULT_CUTOFF_TIME && time < '12:00:00';
}

export function getTimeRangeByValue(val, cutoffTime, withTime = true) {
  let isCrossDays = isCutoffTimeCrossDays(cutoffTime);

  if (!isCrossDays) {
    return getWithinSameDayTimeRangeByValue(val, cutoffTime);
  }

  return getCrossDaysTimeRangeByValue(val, cutoffTime, withTime);
}

export function getTodayTimeRangeByCutoffTime(reportCutoffTime) {
  const { startAt, endAt } = getTimeRangeByValue('today', reportCutoffTime);
  return [startAt, endAt];
}

export function getRangeByCertainDay(date, reportCutoffTime) {
  let startDate = date;
  if (!moment.isMoment(date)) {
    startDate = moment(date, 'MM/DD/YYYY');
    if (!startDate.isValid()) startDate = moment(date, 'MM-DD-YYYY');
  }

  const crossDays = isCutoffTimeCrossDays(reportCutoffTime);
  let endDate;

  if (crossDays) {
    startDate = getDateTimeByCutoffTime(startDate, reportCutoffTime);
    endDate = startDate.clone().add(1, 'd');
  } else {
    startDate.hour(0).minute(0).second(0);
    endDate = startDate.clone().hour(23).minute(59).second(59);
  }

  return {
    startAt: startDate,
    endAt: endDate,
  };
}

export function getEndOfToday(reportCutoffTime) {
  const today = getTodayMoment(reportCutoffTime);
  return today.endOf('day');
}

export function disableDayAfterToday(currentDate, reportCutoffTime) {
  const endOfToday = getEndOfToday(reportCutoffTime);
  return currentDate && currentDate.format('YYYY-MM-DD') > endOfToday.format('YYYY-MM-DD');
}

export function disableDayAfterFeb(currentDate, reportCutoffTime) {
  return currentDate && currentDate.format('YYYY-MM-DD') > moment('2024-01-31').format('YYYY-MM-DD');
}

export function disableDayBeforeToday(currentDate) {
  return currentDate && currentDate.format('YYYY-MM-DD') < getEndOfToday().format('YYYY-MM-DD');
}

export function getPrepaidCardMaxBonusRatio(prepaidCardSettings) {
  if (!prepaidCardSettings || !prepaidCardSettings.is_active) return 0;

  return (prepaidCardSettings.purchaseOptions || []).reduce((currentMaxRatio, item) => {
    let { purchaseAmount, bonusAmount } = item;
    if (bonusAmount / purchaseAmount > currentMaxRatio) {
      currentMaxRatio = bonusAmount / purchaseAmount;
    }
    return currentMaxRatio;
  }, 0);
}

export function getRestaurantCostPlusStartTime(restaurantInfo) {
  const { restaurant_contracts } = restaurantInfo;
  if (!restaurant_contracts) return null;
  const posContract = restaurant_contracts.find((contract) => contract.distribution_mode === DISTRIBUTION_MODES.POS);
  if (!posContract) return null;
  return posContract.cost_plus_start_at;
}

export function JoinClassName(...classNameList) {
  return classNameList.join(' ');
}

export const UCS2Encoding = {
  encode: (data_Uint8Array) => {
    let string = '';

    for (let i = 0, l = data_Uint8Array.length - 1; i < l; i += 2) {
      // Skipping a potential trailing odd byte

      const charCode = data_Uint8Array[i] + data_Uint8Array[i + 1] * 256;
      const char = String.fromCharCode(charCode);

      string += char;
    }

    return string;
  },
  decode: (data_string) => {
    const uint8 = new Uint8Array(data_string.length * 2);

    for (let i = 0, l = data_string.length; i < l; i++) {
      const charCode = data_string.charCodeAt(i);
      const hi = charCode >> 8;
      const lo = charCode % 256;

      uint8[i * 2] = lo;
      uint8[i * 2 + 1] = hi;
    }

    return uint8;
  },
};

export function timeIsOverlap(requestTimeRangeA, requestTimeRangB) {
  if (
    (requestTimeRangeA[0].isSameOrAfter(requestTimeRangB[0]) &&
      requestTimeRangeA[0].isSameOrBefore(requestTimeRangB[1])) ||
    (requestTimeRangeA[1].isSameOrAfter(requestTimeRangB[0]) &&
      requestTimeRangeA[1].isSameOrBefore(requestTimeRangB[1])) ||
    (requestTimeRangeA[0].isSameOrBefore(requestTimeRangB[0]) &&
      requestTimeRangeA[1].isSameOrAfter(requestTimeRangB[1]))
  ) {
    return true;
  }
  return false;
}

export function getNavigatorLocaleSetting() {
  let defaultLanguage = LOCALE_EN;
  if (typeof window !== 'undefined') {
    defaultLanguage = (window.navigator.language || 'en-US').toLowerCase().split('-')[0];
    if (defaultLanguage !== LOCALE_EN && defaultLanguage !== LOCALE_ZH) {
      defaultLanguage = LOCALE_EN;
    }
  }
  return defaultLanguage;
}

export const trimDecimalPlace = (amount, type = 'round', place = 2) => {
  let originVal = +amount;
  if (isNaN(originVal)) {
    return 0;
  }

  const flag = originVal < 0 ? -1 : 1;
  const v = Math.abs(originVal);
  const num = Math.pow(10, place);
  const dn = new Decimal(v);
  const bn = dn.times(num).toNumber();
  if (type === 'round') {
    return flag * (Math.round(bn) / num);
  }

  return flag * (Math.floor(bn) / num);
};

export const customGetItemName = ({ item, name = 'name', foreign_name = 'foreign_name', locale = LOCALE_EN }) => {
  if (!item) return '';
  if (locale === LOCALE_EN) return item[name];
  return item[foreign_name] || item[name] || '';
};

export const getValueBySpaces = (value) => {
  const match = String(value).match(/^\s+/);
  if (match) {
    const spaces = match[0].length;
    const ratio = Math.floor(spaces / 4);
    return ratio;
  }
  return 0;
};

export const replaceNullsWithUndefined = (obj) => {
  const newObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      newObj[key] = obj[key] === null ? undefined : obj[key];
    }
  }
  return newObj;
};

export function isNullOrUndefined(obj) {
  return obj === null || obj === undefined;
}

/**
 * 对简单数组with的简单实现
 * arr：初始数组对象
 * index：赋值索引
 * value：值
 */
export function arrWithPolyfill(arr, index, value) {
  const length = arr.length;
  const changeIndex = index >= 0 ? index : length + index;
  const copyArr = [...arr];
  copyArr[changeIndex] = value;
  return copyArr;
}

export function checkUserAction(useNoActionCallback, timeout) {
  var timer = false;

  function checkActivity() {
    clearTimeout(timer);
    timer = setTimeout(() => {
      clearListener();
      useNoActionCallback();
    }, timeout || 5000);
  }

  function clearListener() {
    timer && clearTimeout(timer);
    document.removeEventListener('keydown', checkActivity);
    document.removeEventListener('mousedown', checkActivity);
    document.removeEventListener('mousemove', checkActivity);
    document.removeEventListener('touchstart', checkActivity);
    document.removeEventListener('scroll', checkActivity);
  }

  document.addEventListener('keydown', checkActivity);
  document.addEventListener('mousedown', checkActivity);
  document.addEventListener('mousemove', checkActivity);
  document.addEventListener('touchstart', checkActivity);
  document.addEventListener('scroll', checkActivity);

  checkActivity();

  return () => {
    clearListener();
  };
}

/**
 * 将电话号码字符串格式化为标准格式
 * @param {string} phoneNumber - 需要格式化的电话号码
 * @returns {string} 格式化后的电话号码
 * @example
 * // 返回 "(555) 123-4567"
 * formatPhoneNumber("5551234567")
 *
 * // 返回 "+1 (555) 123-4567"
 * formatPhoneNumber("15551234567")
 *
 * // 如果不是10位或11位数字，返回原始输入
 * formatPhoneNumber("123") // "123"
 */
export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) return '';

  const cleaned = phoneNumber.replace(/\D/g, '');

  if (cleaned.length === 10) {
    // 对于10位电话号码格式化为: +1 (XXX) XXX-XXXX
    const areaCode = cleaned.slice(0, 3);
    const firstPart = cleaned.slice(3, 6);
    const secondPart = cleaned.slice(6, 10);
    return `+1 (${areaCode}) ${firstPart}-${secondPart}`;
  } else if (cleaned.length === 11) {
    // 对于11位电话号码格式化为: +X (XXX) XXX-XXXX
    const countryCode = cleaned.slice(0, 1);
    const areaCode = cleaned.slice(1, 4);
    const firstPart = cleaned.slice(4, 7);
    const secondPart = cleaned.slice(7, 11);
    return `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
  }

  return phoneNumber;
}

 /* 为需要展示为负数的正数添加负号
 * value: number
 * 123 => -123
 * 0 => 0
 */
export function addMinusSignal(value) {
  return isNullOrUndefined(value) ? undefined : value === 0 ? 0 : -value;
}
