import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { TIP_TYPE_STAFF } from 'src/consts/enum';
import {
  COMPACT_DATE_FORMAT,
  PAYOUT_SOURCE_TYPE_RESTAURANT_CONTRACT,
  PAYOUT_TYPE_OFFLINE,
  PAYOUT_TYPE_AUTOMATIC,
  PAYOUT_STATE_DRAFT,
  PAYOUT_STATE_CANCELED,
  PLATFORM_CHOWBUS,
  PAYOUT_SOURCE_TYPE_MOKOMO,
  POS_INCOME_TYPE_REBATE,
  POS_INCOME_TYPE_OTHERS,
  POS_SPECIFIC_TYPE_INCOME,
  PAYOUT_STATE_SETTLED,
  PAYOUT_PAID_IN_FULL,
} from 'src/components/MonthlyStatement/const';
import { INTERNAL_SALES_REPORT_API_PREFIX, PLATFORM_WEBSITE, SALES_REPORT_API_PREFIX } from 'src/consts';
import { getAppInfos } from './utils';
import { isNotEmptyArray } from 'src/utils/array';
import { countColumns, costplus_adjustment_type } from 'src/components/Reports/const';
import {
  formatCurrency,
  DEFAULT_TIME_ZONE,
  trimDecimalPlace,
  getRestaurantCostPlusStartTime,
  getRestaurantTimeZone,
} from 'src/utils/utils';
import { isNotEmptyObject } from './object';

export function getReportServiceApiPrefix() {
  const { isMonthlyReport } = getAppInfos();
  return isMonthlyReport ? INTERNAL_SALES_REPORT_API_PREFIX : SALES_REPORT_API_PREFIX;
}

export const checkMonthlyReport = () => {
  const { isMonthlyReport } = getAppInfos();
  return isMonthlyReport;
};

export function getHourlyIntervalSummaryData({ hourlyDatas, fields = [] } = {}) {
  if (!hourlyDatas) return [];
  hourlyDatas.sort((a, b) => {
    const { date: d1, hour: h1 } = a;
    const { date: d2, hour: h2 } = b;
    const [month1, date1, year1] = d1.split('/');
    const [month2, date2, year2] = d2.split('/');
    const dateStr1 = `${year1}${month1}${date1}`;
    const dateStr2 = `${year2}${month2}${date2}`;
    return dateStr1 - dateStr2 || h1 - h2;
  });
  const chartData = [];
  hourlyDatas.forEach((item) => {
    fields.forEach((field) => {
      let y = 0;
      if (item) y = item[field]?.amount || 0;
      chartData.push({
        x: item.hour,
        y,
        series: field,
      });
    });
  });
  return chartData;
}

export function getDailyIntervalSummaryData({ dailyDatas, fields = [], startAt, endAt }) {
  if (!dailyDatas) return [];
  let start = startAt.clone();
  const chartData = [];
  const format = 'MM/DD/YYYY';

  while (start.format('YYYY-MM-DD') <= endAt.format('YYYY-MM-DD')) {
    const x = start.format(format);
    const item = dailyDatas.find((_) => _.date === x);
    fields.forEach((field) => {
      let y = 0;
      if (item) {
        if (countColumns.includes(field)) {
          y = item.counts?.[field] || 0;
        } else {
          y = item[field]?.amount || 0;
        }
      }
      chartData.push({
        x: start.format('MM/DD'),
        y,
        series: field,
      });
    });
    start = start.clone().add(1, 'd');
  }

  return chartData;
}

export function getMonthlyIntervalSummaryData({ dailyDatas, fields = [], startAt, endAt }) {
  if (!dailyDatas) return [];
  let start = startAt.clone();
  const format = 'MM/YYYY';
  let chartData = [];

  while (start.format(format) <= endAt.format(format)) {
    const obj = {};
    const month = start.format(format);
    // eslint-disable-next-line no-loop-func
    dailyDatas.forEach((earning) => {
      let { date } = earning;
      date = moment(date, 'MM/DD/YYYY').format('YYYY-MM');
      if (date === month) {
        fields.forEach((field) => {
          let value;

          if (countColumns.includes(field)) {
            value = earning.counts?.[field] || 0;
          } else {
            value = earning[field]?.amount || 0;
          }

          if (!obj[field]) {
            obj[field] = value;
          } else {
            obj[field] += value;
          }
        });
      }
    });
    const isObjEmpty = Object.keys(obj).length === 0;

    fields.forEach((field) => {
      let y = !isObjEmpty ? obj[field] : 0;
      chartData.push({
        x: month,
        y,
        series: field,
      });
    });

    start = start.clone().add(1, 'month');
  }

  return chartData;
}

export function retrieveTipsBreakdowns(sourceData) {
  let cash_tips = 0;
  let card_tips = 0;
  let online_tips = 0;
  let other_tips = 0;
  let card_tips_refund = 0;
  let cash_tips_refund = 0;
  let other_tips_refund = 0;
  let online_tips_refund = 0;
  let tips = 0;

  if (sourceData) {
    Object.keys(sourceData).forEach((column) => {
      const { refund, total = 0, amount = 0 } = sourceData?.[column];
      tips += amount;

      if (column === 'cash') {
        cash_tips = total;
        cash_tips_refund = refund;
      } else if (column === 'online') {
        online_tips = total;
        online_tips_refund += refund;
      } else if (['card', 'card_present'].includes(column)) {
        card_tips += total;
        card_tips_refund += refund;
      } else {
        other_tips += total;
        other_tips_refund += refund;
      }
    });
  }

  return [
    {
      name: 'Cash Tips',
      foreign_name: '现金小费',
      amount: cash_tips,
    },
    {
      name: 'Card Tips',
      foreign_name: '刷卡小费',
      amount: card_tips,
    },
    {
      name: 'Online Tips',
      foreign_name: '线上小费',
      amount: online_tips,
    },
    {
      name: 'Other Tips',
      foreign_name: '其他小费',
      amount: other_tips,
    },
    {
      name: 'Tips Refund',
      foreign_name: '小费退款',
      amount: -(card_tips_refund + cash_tips_refund + online_tips_refund + other_tips_refund),
    },
  ];
}

export function retrieveTipsColumns(sourceData, tipType = TIP_TYPE_STAFF) {
  const { tips_detail, driver_tips_detail } = sourceData;
  const payment = (tipType === TIP_TYPE_STAFF ? tips_detail : driver_tips_detail)?.payment || {};
  let cash_tips = 0;
  let card_tips = 0;
  let online_tips = 0;
  let other_tips = 0;
  let card_tips_refund = 0;
  let cash_tips_refund = 0;
  let other_tips_refund = 0;
  let online_tips_refund = 0;
  let tips = 0;

  Object.keys(payment).forEach((column) => {
    const { refund, total = 0, amount = 0 } = payment[column];
    tips += amount;

    if (column === 'cash') {
      cash_tips = total;
      cash_tips_refund = refund;
    } else if (column === 'online') {
      online_tips = total;
      online_tips_refund += refund;
    } else if (['card', 'card_present'].includes(column)) {
      card_tips += total;
      card_tips_refund += refund;
    } else {
      other_tips += total;
      other_tips_refund += refund;
    }
  });

  return {
    cash_tips,
    card_tips,
    online_tips,
    other_tips,
    tips,
    tips_refund: -(card_tips_refund + cash_tips_refund + online_tips_refund + other_tips_refund),
  };
}

export function parsePayoutRecord(record = {}) {
  const { stripe_descriptor, amount, source_type, state, payout_type, summary = {}, id, payout_date } = record;
  const isPayoutOffline = payout_type === PAYOUT_TYPE_OFFLINE;

  if (isPayoutOffline) {
    const { adjustment, adjustment_tips } = summary;
    const adjustmentAmount = +(adjustment.amount || 0);
    const adjustment_tip = +(adjustment_tips?.amount || 0);

    return {
      id,
      state,
      source_type,
      payout_type,
      payout_date,
      amount: adjustmentAmount,
      adjustment: adjustmentAmount,
      adjustment_details: adjustment.details || [],
      adjustment_tip,
      adjustment_tip_details: adjustment_tips?.details || [],
    };
  }

  const isPayoutByPOS = source_type == PAYOUT_SOURCE_TYPE_RESTAURANT_CONTRACT;
  const { start_at, end_at } = record;
  const payout_range = `${start_at || ''} - ${end_at || ''}`;

  let date;

  if (!isPayoutByPOS) {
    const { public_note } = record;
    let matches;
    if (public_note) matches = /Payout\D+(\d+\/\d+\/\d+)\D+(\d+\/\d+\/\d+)/.exec(public_note);
    if (matches && matches.length) {
      const start = matches[1];
      const end = matches[2];
      let startAt = start;
      if (start.length !== COMPACT_DATE_FORMAT.length) {
        startAt = moment(start, COMPACT_DATE_FORMAT).format(COMPACT_DATE_FORMAT);
      }
      let endAt = end;
      if (endAt.length !== COMPACT_DATE_FORMAT.length) {
        endAt = moment(end, COMPACT_DATE_FORMAT).format(COMPACT_DATE_FORMAT);
      }
      date = `${startAt} - ${endAt}`;
    }

    return {
      id,
      date,
      payout_range,
      state,
      source_type,
      payout_type,
      amount: +amount,
      payout_date,
    };
  }

  if (stripe_descriptor) {
    const segments = stripe_descriptor.split(' ');
    const str = segments[segments.length - 1];
    if (str && /[\d\/]+/g.test(str)) date = moment(str, COMPACT_DATE_FORMAT).format(COMPACT_DATE_FORMAT);
  }

  const {
    payment: { amount: payment_amount, count: payment_count } = {},
    pos_processing_fee = {},
    pos_processing_rate = {},
    pos_processing_card_rate = {},
    pos_processing_card_present_rate = {},
    pos_processing_card_fee = {},
    pos_processing_card_present_fee = {},
    refund = {},
    adjustment = {},
    adjustment_tips = {},
  } = summary;

  const transaction_fee =
    +(pos_processing_fee.amount || 0) +
    +(pos_processing_rate.amount || 0) +
    +(pos_processing_card_rate.amount || 0) +
    +(pos_processing_card_present_rate.amount || 0) +
    +(pos_processing_card_fee.amount || 0) +
    +(pos_processing_card_present_fee.amount || 0);
  return {
    id,
    date,
    state,
    source_type,
    payout_type,
    payout_range,
    payment_count: +(payment_count || 0),
    payment_amount: +(payment_amount || 0),
    transaction_fee: +(transaction_fee || 0),
    fees: summary?.costplus_fee?.amount || 0,
    refund: refund.amount || 0,
    amount: +amount,
    adjustment: +(adjustment.amount || 0),
    payout_date,
    adjustment_details: adjustment.details || [],
    adjustment_tip: +(adjustment_tips?.amount || 0),
    adjustment_tip_details: adjustment_tips?.details || [],
  };
}

export function separatePlatforms(platforms) {
  const billingReportPlatforms = [];
  const posReportPlatforms = [];

  platforms.forEach((platform) => {
    if ([PLATFORM_CHOWBUS, PLATFORM_WEBSITE].includes(platform)) {
      billingReportPlatforms.push(platform);
    } else {
      posReportPlatforms.push(platform);
    }
  });

  return {
    billingReportPlatforms,
    posReportPlatforms,
  };
}

export function formatHoursWorked(mins = 0) {
  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;
  if (!mins) return `0 m`;
  const totalHours = Number(mins / 60).toFixed(2);
  let prefix = '';
  if (hours > 0) prefix = `${hours}h`;
  if (!minutes) return prefix;
  return [prefix, minutes ? `${minutes}m` : '', `(${totalHours} h)`].join(' ');
}

export function formatHoursWorkedDisplayH(mins = 0, precision = 2) {
  /**
   * 只显示以小时为单位表示的小数
   * 默认保留两位小数
   * 整数的话不显示小数部分 1.00 显示 1
   */
  let resultValue = 0;
  const totalHours = Number(mins / 60);
  if (totalHours === Math.floor(totalHours)) {
    resultValue = totalHours.toFixed(0);
  } else {
    resultValue = totalHours.toFixed(precision);
  }

  return resultValue;
}

export function formatHours(hours = 0, precision = 2) {
  let resultValue = 0;
  const totalHours = Number(hours / 100);
  if (totalHours === Math.floor(totalHours)) {
    resultValue = totalHours.toFixed(0);
  } else {
    resultValue = totalHours.toFixed(precision);
  }

  return resultValue;
}

const buildRange = (record = {}) => {
  if (record?.start_at && record?.end_at) {
    return `${record.start_at} - ${record.end_at}`;
  }
  const summary = record.summary;
  if (!summary?.adjustment || !summary?.adjustment?.details) {
    return '';
  }
  const details = summary?.adjustment?.details;
  let start = '',
    end = '';
  if (Array.isArray(details) && details.length > 0) {
    details.forEach((d) => {
      const s = d?.start_at;
      const e = d?.end_at;

      if (!s || !e) {
        return;
      }

      if (start === '' || moment(start).isAfter(s)) {
        start = s;
      }

      if (end === '' || moment(end).isBefore(e)) {
        end = e;
      }
    });
  }
  return `${start} - ${end}`;
};

const buildPayoutType = (record = {}) => {
  const v = record?.specific_type || '';
  if (v) {
    return v;
  }

  let temp = 'Payout';

  const details = record?.summary?.adjustment?.details;
  if (!Array.isArray(details) || details.length === 0) {
    return temp;
  }

  let specificTypes = [];
  let incomeTypes = [];
  details.forEach((item) => {
    const v = item.specific_type;
    const { specific_type, income_type } = item;
    if (specific_type) {
      if (specificTypes.length == 0 || !specificTypes.includes(specific_type)) {
        specificTypes.push(specific_type);
      }
    }
    if (income_type) {
      if (incomeTypes.length == 0 || !incomeTypes.includes(income_type)) {
        incomeTypes.push(income_type);
      }
    }
  });
  if (specificTypes.length == 1) {
    temp = specificTypes[0];
  }
  if (incomeTypes.every((item) => item.toLowerCase().includes('rebate'))) {
    temp = incomeTypes[0];
  }

  return temp || 'Payout';
};

export const payoutInhouseData = (records) => {
  const { payouts } = parsePayoutReportDatas(records);
  return payouts.filter((item) => item.source_type !== PAYOUT_SOURCE_TYPE_MOKOMO);
};

export const parsePayoutReportDatas = (records = []) => {
  let payouts = [],
    fees = [];
  if (!Array.isArray(records) || records.length === 0) {
    return { payouts, fees };
  }

  records.forEach((record) => {
    const type = (record?.specific_type || '').toLowerCase();
    const amount = record?.amount || 0;
    const state = record?.state || '';
    const payout_type = record?.payout_type || '';
    if (
      payout_type.toLowerCase() === PAYOUT_TYPE_AUTOMATIC ||
      [PAYOUT_STATE_DRAFT, PAYOUT_STATE_CANCELED].includes(state.toLowerCase())
    ) {
      return;
    }
    const is_refund = !!record?.is_refund;
    if (is_refund) {
      fees.push(parseFeeData(record));
      return;
    }

    if (['tip adjustment', 'payout'].includes(type) || payout_type === costplus_adjustment_type || +amount > 0) {
      payouts.push(parsePayoutData(record));
    } else {
      fees.push(parseFeeData(record));
    }
  });

  payouts = buildCostPlusRange(payouts);
  fees = buildCostPlusRange(fees);

  return { payouts, fees };
};

export const parseAdyenReportData = (obj, restaurantInfo) => {
  const isCostplus = !!getRestaurantCostPlusStartTime(restaurantInfo);

  let payouts = [],
    fees = [];
  const { payouts: payoutsRaw, invoices: invoicesRaw } = obj;

  if (isNotEmptyArray(payoutsRaw)) {
    payoutsRaw.forEach((pa, pi) => {
      const st = momentTimezone.tz(pa.start_at, getRestaurantTimeZone()).format('YYYY-MM-DD HH:mm:ss');
      const et = momentTimezone.tz(pa.end_at, getRestaurantTimeZone()).format('YYYY-MM-DD HH:mm:ss');

      let pItem = {
        id: `${pa.payout_id}`,
        type: pa.payout_type,
        payout_range: `${st} - ${et}`,
        payout_date: (pa.payout_date || '').slice(0, 10),
        payment_amount: pa.payment_amount,
        others: pa.others,
        fees: pa.fees,
        refund: pa.refund,
        transaction_fee: pa.transaction_fees,
        amount: pa.amount,
        state: pa.state || '',
        note: buildAdyenPayoutNote(pa),
      };

      if (isCostplus) {
        pItem.fees = trimDecimalPlace(+pItem.fees + +pItem.transaction_fee);
        pItem.transaction_fee = null;
      }

      payouts.push(pItem);
    });
  }

  if (isNotEmptyArray(invoicesRaw)) {
    invoicesRaw.forEach((iv) => {
      fees.push({
        id: iv.invoice_id,
        type: iv.payout_type,
        payout_date: (iv.payout_date || '').slice(0, 10),
        item_name: buildAdyenItemNames(iv),
        status: iv.status === 'paid' ? 'Paid in Full' : iv.status,
        amount: iv.sum_amount,
        sum_amount: iv.amount,
        tax: iv.tax,
        download_link: iv.download_link,
      });
    });
  }

  return { payouts, fees };
};

const buildAdyenPayoutNote = (payout) => {
  const notes = [];
  const note = payout.note || payout.public_note || payout.description;
  const detail_items = payout.detail_items;
  let tips = [];
  let adjusts = [];
  if (isNotEmptyArray(detail_items)) {
    detail_items.forEach((item) => {
      const amount = formatCurrency(trimDecimalPlace(item.amount), { div100: false });
      if (item.name.toLowerCase().includes('pos tip adjustment')) {
        tips.push(`${item.key}: ${amount}`);
      }

      if (item.name.toLowerCase().includes('pos adjustment')) {
        adjusts.push(`${item.key}: ${amount}`);
      }
    });
  }

  if (note) {
    notes.push(note);
  }
  if (tips.length > 0) {
    notes.push(...tips);
  }
  if (adjusts.length > 0) {
    notes.push(...adjusts);
  }

  return notes.join('\n');
};

const buildAdyenItemNames = (invoice) => {
  let note = invoice.public_note || '--';
  const details = invoice.detail_items;
  let startEndAt = '';

  if (invoice?.start_at && invoice?.end_at) {
    startEndAt = `Service Date: ${formatRange(invoice.start_at)} - ${formatRange(invoice.end_at)}`;
    note = `${startEndAt}\n${note}`;
  }

  if (!isNotEmptyArray(details)) {
    return note;
  }

  let detailNotes = details.map((item) => {
    return `${item.name}:${formatCurrency(trimDecimalPlace(item.amount), { div100: false })}`;
  });

  return `${note}\n${detailNotes.join('\n')}`;
};

export const buildCostPlusRange = (items) => {
  if (!isNotEmptyArray(items)) {
    return items;
  }

  return items.map((item) => {
    const { type, payout_type, note = '', public_note, payout_range } = item;

    if (payout_type === costplus_adjustment_type) {
      const str = note.toLowerCase();
      let costplusType = 'Cost Plus Adjustment';

      if (str.includes('fees adjustment')) {
        costplusType = 'Monthly Fees Adjustment';
      } else if (str.includes('processing fees')) {
        costplusType = 'Processing Fees';
      }

      return {
        ...item,
        type: costplusType,
        fees: item.amount,
      };
    }

    if (type.toLowerCase() === 'cost plus fee') {
      const businessRange = timeRangeFromChargeDate(item.payout_date);
      return {
        ...item,
        payout_range: businessRange || payout_range,
      };
    }
    return item;
  });
};

const timeRangeFromChargeDate = (chargeDate, formatStr = 'YYYY-MM-DD') => {
  try {
    const st = moment(chargeDate).subtract(1, 'month').startOf('month').format(formatStr);
    const et = moment(chargeDate).subtract(1, 'month').endOf('month').format(formatStr);

    return `${st} - ${et}`;
  } catch (error) {
    return '';
  }
};

const parseFeeData = (record = {}) => {
  const { id, payout_date, amount } = record;
  const type = buildFeeType(record);
  const status = buildStatus(record.state);
  const hasInvoiceLink = status === PAYOUT_PAID_IN_FULL && +amount != 0;
  return {
    id,
    type,
    payout_type: record?.payout_type,
    payout_range: buildRange(record),
    payout_date: payout_date,
    item_name: buildFeeItemName(record, type, payout_date),
    status,
    amount: amount,
    sum_amount: buildFromSummary(record, 'basic_amount', amount),
    tax: buildFromSummary(record, 'tax_amount'),
    download_link: hasInvoiceLink ? record?.download_link || '' : '',
  };
};

const buildFromSummary = (record = {}, key, defaultAmount = 0) => {
  const arr = record?.summary?.adjustment?.details || [];
  const total_amount = +record?.amount;
  let amount = arr.reduce((value, obj) => {
    const v = obj[key];
    return v ? value + +v : value;
  }, 0);

  amount = trimDecimalPlace(amount);

  if (amount === 0) {
    amount = defaultAmount;
  }

  return total_amount * amount > 0 ? amount : -1 * amount;
};

const buildStatus = (state) => {
  if (!state) {
    return '';
  }
  return state.toLowerCase() === PAYOUT_STATE_SETTLED ? PAYOUT_PAID_IN_FULL : state;
};

const formatRange = (value) => {
  try {
    return moment(value).format('MM/DD/YYYY');
  } catch (error) {
    return value;
  }
};

const buildItemRange = (record = {}) => {
  if (record?.start_at && record?.end_at) {
    return `${formatRange(record.start_at)} - ${formatRange(record.end_at)}`;
  }
  const summary = record.summary;
  if (!summary?.adjustment || !summary?.adjustment?.details) {
    return '';
  }
  const details = summary?.adjustment?.details;

  let start = '',
    end = '';
  if (Array.isArray(details) && details.length > 0) {
    details.forEach((d) => {
      const s = d?.start_at;
      const e = d?.end_at;

      if (!s || !e) {
        return;
      }

      if (start === '' || moment(start).isAfter(s)) {
        start = s;
      }

      if (end === '' || moment(end).isBefore(e)) {
        end = e;
      }
    });
  }

  return !!start && !!end ? `${formatRange(start)} - ${formatRange(end)}` : '';
};

const buildFeeItemName = (record = {}, type, payout_date) => {
  const summary = record?.summary;
  const details = summary?.adjustment?.details;
  const dateDescItems = [];
  if (type.toLowerCase() === 'cost plus fee') {
    const tr = timeRangeFromChargeDate(payout_date, 'MM/DD/YYYY');
    if (tr) {
      dateDescItems.push(`Transaction Date:${tr}`);
    }
  } else {
    const sd = buildItemRange(record);
    if (sd) {
      dateDescItems.push(`Service Date:${sd}`);
    }
  }

  if (record.payout_type === costplus_adjustment_type) {
    const rsd = record?.stripe_descriptor || '';
    if (rsd) {
      dateDescItems.push(`${rsd}`);
    }
  }

  const pr = dateDescItems.join(';\n ');
  if (!Array.isArray(details) || details.length === 0) {
    return pr;
  }

  const types = details.map((item) => {
    const amount = formatCurrency(trimDecimalPlace(item.amount), { div100: false });
    return `${item.public_note} -- ${amount}`;
  });

  if (pr) {
    types.unshift(pr);
  }

  return types.join(';\n ');
};

const buildFeeType = (record = {}) => {
  if (record?.is_refund) {
    return 'Invoice Refund';
  }
  const v = record?.specific_type || '';
  if (v) {
    return v;
  }

  let temp = 'Invoice';
  const summary = record.summary;
  if (!summary?.adjustment || !Array.isArray(summary?.adjustment?.details)) {
    return temp;
  }

  const details = summary?.adjustment?.details;
  if (Array.isArray(details) && details.length > 0) {
    const names = details.map((d) => d?.item_name || '');
    if (names.every((item) => item.toLowerCase().includes('saas'))) {
      return 'SaaS Fee';
    }
  }

  return temp;
};

const parsePayoutData = (record) => {
  return record.source_type !== PAYOUT_SOURCE_TYPE_MOKOMO
    ? parseInhousePayoutData(record)
    : parseOnlinePayoutData(record);
};

const buildProcessingFees = (summary) => {
  if (!isNotEmptyObject(summary)) {
    return 0;
  }

  let amount = 0;
  Object.keys(summary).forEach((k) => {
    if (k.includes('pos_processing')) {
      amount += +(summary[k]?.amount || '');
    }
  });
  return amount;
};

const parseInhousePayoutData = (record) => {
  const { id, payout_date, amount, state, summary = {} } = record;
  const { refund = {} } = summary;

  let payment_amount = 0;
  if (summary?.payment?.amount) {
    payment_amount = +(summary.payment.amount || 0);
  }
  if (summary?.adjustment_tips?.amount) {
    payment_amount = payment_amount + +summary?.adjustment_tips?.amount;
  }

  let refund_amount = 0;
  if (refund?.amount) {
    refund_amount = +refund.amount;
  }

  const transaction_fee = buildProcessingFees(summary);

  let fee_amount = +(summary?.costplus_fee?.amount || 0);

  return {
    id,
    type: buildPayoutType(record),
    payout_type: record?.payout_type,
    payout_range: buildRange(record),
    payout_date: payout_date,
    payment_amount: payment_amount, // Total Transaction
    others: buildOtherFee(record),
    fees: fee_amount + buildPostOtherFees(record),
    refund: refund_amount,
    adjustment_details: summary?.adjustment_tips?.details || [],
    transaction_fee: transaction_fee || 0,
    amount: amount,
    source_type: record.source_type,
    state,
    note: buildItemNote(record),
  };
};

export const invalidPayout = (data) => {
  return (
    +data.amount != 0 &&
    +data.payment_amount == 0 &&
    +data.fees == 0 &&
    +data.refund == 0 &&
    +data.transaction_fee == 0 &&
    +data.others == 0
  );
};

const parseOnlinePayoutData = (record) => {
  const { id, payout_date, amount, state, summary = {} } = record;
  const {
    pos_processing_fee = {},
    pos_processing_rate = {},
    pos_processing_card_rate = {},
    pos_processing_card_present_rate = {},
    pos_processing_card_fee = {},
    pos_processing_card_present_fee = {},
  } = summary;

  const transaction_fee =
    +(pos_processing_fee.amount || 0) +
    +(pos_processing_rate.amount || 0) +
    +(pos_processing_card_rate.amount || 0) +
    +(pos_processing_card_present_rate.amount || 0) +
    +(pos_processing_card_fee.amount || 0) +
    +(pos_processing_card_present_fee.amount || 0);

  return {
    id,
    type: buildPayoutType(record),
    payout_type: record?.payout_type,
    payout_range: buildRange(record),
    payout_date: payout_date,
    payment_amount: summary?.total_transaction?.total_amount || 0, // Total Transaction
    others: buildOtherFee(record),
    fees: summary?.fee?.total_amount || 0,
    refund: summary?.refund?.total_amount || 0,
    adjustment_details: summary?.adjustment_tips?.details || [],
    transaction_fee: transaction_fee || 0,
    amount: amount,
    source_type: record.source_type,
    state,
    note: buildItemNote(record),
  };
};

const buildOtherFee = (record) => {
  const giftCardsFees = ['wallet/gift_card/order', 'wallet/kaleplus/transaction'].reduce((sum, k) => {
    const v = record?.summary?.[k]?.amount || 0;
    return sum + +v;
  }, 0);

  const details = record?.summary?.adjustment?.details || [];

  if (!isNotEmptyArray(details)) {
    return giftCardsFees;
  }

  const fees = details.reduce((sum, item) => {
    const sp = item?.specific_type || '';
    if (sp.toLowerCase() === POS_SPECIFIC_TYPE_INCOME) {
      sum = sum + +item.amount;
    }
    return sum;
  }, 0);

  return giftCardsFees + fees;
};

const buildPostOtherFees = (record) => {
  const summary = record?.summary;
  const details = summary?.adjustment?.details || [];
  return details.reduce((amount, item) => {
    const incomeType = item?.income_type?.toLowerCase() || '';
    const sp = item?.specific_type || '';
    if (
      sp.toLowerCase() !== POS_SPECIFIC_TYPE_INCOME &&
      [POS_INCOME_TYPE_REBATE, POS_INCOME_TYPE_OTHERS].includes(incomeType)
    ) {
      const v = item?.amount ? +item.amount : 0;
      return amount + v;
    }
    return amount;
  }, 0);
};

const buildItemNote = (record = {}) => {
  let note = record?.public_note || record?.stripe_descriptor || '';
  const summary = record?.summary;
  let adjustment_details = summary?.adjustment?.details || [];
  const adjustment_tips_details = summary?.adjustment_tips?.details || [];

  let details = [];
  if (isNotEmptyArray(adjustment_details)) {
    details = details.concat(adjustment_details);
  }
  if (isNotEmptyArray(adjustment_tips_details)) {
    details = details.concat(adjustment_tips_details);
  }

  if (!isNotEmptyArray(details)) {
    return note;
  }

  const types = details.map((item) => {
    const amount = formatCurrency(trimDecimalPlace(item.amount), { div100: false });
    const v = `${item.income_type ? item.income_type + ':' : ''}${item.public_note || ''} ${amount}`;
    if (item?.income_type?.toLowerCase() === POS_INCOME_TYPE_OTHERS) {
      const name = item?.item_name || item?.income_type || v;
      return details.length > 1 ? `${name} ${amount}` : name;
    }
    return v;
  });

  return types.join(';\n ');
};

export const validMonth = () => {
  const tutc = moment.utc();
  const tege = momentTimezone.tz(DEFAULT_TIME_ZONE).clone().date(6).hours(14).minutes(0).seconds(0).utc();

  if (tutc.isAfter(tege)) {
    return 'lastMonth';
  }

  return 'twoMonthsAgo';
};

// 6th each month, 14:00:00 PM  CST
export const costPlusCstTime = (currentDate) => {
  if (!currentDate) {
    return false;
  }
  const tutc = moment.utc();
  const tege = momentTimezone.tz(DEFAULT_TIME_ZONE).clone().date(6).hours(14).minutes(0).seconds(0).utc();

  const year = tege.year();
  let month = tege.month(); // last month

  if (tutc.isAfter(tege)) {
    month = month + 1; // current month
  }

  if (currentDate.year() < year) {
    return false;
  }

  if (currentDate.month() + 1 < month) {
    return false;
  }

  return true;
};
