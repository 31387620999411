export const BREAK_TYPE_PAID = 'paid';
export const BREAK_TYPE_UNPAID = 'unpaid';

export const DOWNLOAD_CONTENT_TYPE_CSV = 'text/csv,charset=UTF-8';

export const OPEN_CASH_PURPOSES = {
  home_page: {
    name: 'Home page > Cash Drawer',
    foreign_name: '首页钱箱管理',
  },
  cash: {
    name: 'Pay by Cash',
    foreign_name: '现金支付',
  },
  cash_refund: {
    name: 'Cash Refund',
    foreign_name: '现金退款',
  },
};

export const TIP_TYPE_STAFF = 'staff';
export const TIP_TYPE_DRIVER = 'driver';

const env = process.env.STAGING_ENV || 'staging-02';

export const PRINTING_API_PREFIX = {
  'staging-01': 'https://api-staging-01.chowbus.com/pos/printing',
  'staging-02': 'https://api-staging-02.chowbus.com/pos/printing',
  production: 'https://api.chowbus.com/pos/printing',
}[env];

export const PAYOUT_STATUS_MAPPING = {
  pending: {
    name: 'Pending',
    foreign_name: '待打款',
  },
  retrying: {
    name: 'Retrying',
    foreign_name: '打款重试中',
  },
  fund_transfer_successful: {
    name: 'Fund transfer successful',
    foreign_name: '转账成功',
  },
  fund_transfer_failed: {
    name: 'Fund transfer failed',
    foreign_name: '转账失败',
  },
  in_transit: {
    name: 'In transit',
    foreign_name: '在途中',
  },
  settled: {
    name: 'Settled',
    foreign_name: '成功',
  },
  failed: {
    name: 'Failed',
    foreign_name: '失败',
  },
  canceled: {
    name: 'Canceled',
    foreign_name: '已取消',
  },
  paid: {
    name: 'Settled',
    foreign_name: '成功',
  },
};
export const PAYOUT_ADJUSTMENT_REBATE = 'POS Rebate';
export const REPORT_SHORT_TIME_FORMAT = 'MM-DD-YYYY';
export const REPORT_LONG_TIME_FORMAT = 'MM-DD-YYYYTHH:mm:ss';
export const NORMAL_DATE_FORMAT = 'YYYY-MM-DD';
export const COMPACT_DATE_FORMAT = 'MM/DD/YYYY';
export const DEFAULT_CUTOFF_TIME = '00:00:00';
export const LOYALTY_TYPES = [
  {
    type: 'prepaid_card',
    name: 'Prepaid Card',
    foreign_name: '会员充值',
    value: ['prepaid_card', 'restaurant_group_prepaid_card'],
  },
  {
    type: 'gift_card',
    name: 'Gift Card',
    foreign_name: '礼品卡',
    value: ['gift_card', 'restaurant_group_gift_card'],
  },
  {
    type: 'annual_membership_fee',
    name: 'Annual Membership Fee',
    foreign_name: '会员年费',
    value: ['annual_membership_fee', 'restaurant_group_annual_membership_fee'],
  },
  {
    type: 'kaleplus_gift_card',
    name: 'Kaleplus Gift Card',
    foreign_name: 'kaleplus礼品卡',
    value: ['kaleplus_gift_card'],
  },
];

export const OPEN_PRICE_UNIT_MAPPING = {
  lb: {
    name: 'Pound',
    foreign_name: '磅',
  },
  kg: {
    name: 'Kilogram',
    foreign_name: '千克',
  },
  oz: {
    name: 'Ounce',
    foreign_name: '盎司',
  },
  g: {
    name: 'Gram',
    foreign_name: '克',
  },
};

export const OPEN_PRICE_UNITS = Object.keys(OPEN_PRICE_UNIT_MAPPING).map((key) => ({
  ...OPEN_PRICE_UNIT_MAPPING[key],
  value: key,
}));

export const TRANSFERRED_LINE_ITEMS_STATUS = 'transferred';
export const MONTH_ABBRS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const LOGIN_METHOD_DEVICE_PIN = 'device_pin';
export const LOGIN_METHOD_SCAN_QR = 'scan_pin';
export const GROUP_COMMON = 'ios';

export const HALF_DAY_PERIOD = {
  AM: 'am',
  PM: 'pm',
};

export const OVERTIME_TYPES = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  CONSECUTIVE_DAYS: 'consecutive_days',
};

export const OVERTIME_TYPES_MAPPING = {
  [OVERTIME_TYPES.DAILY]: {
    name: 'Daily Hours',
    foreign_name: '每日工作时间',
    value: OVERTIME_TYPES.DAILY,
    disabled: false,
  },
  [OVERTIME_TYPES.WEEKLY]: {
    name: 'Weekly Hours',
    foreign_name: '每周工作时间',
    value: OVERTIME_TYPES.WEEKLY,
    disabled: false,
  },
  [OVERTIME_TYPES.CONSECUTIVE_DAYS]: {
    name: 'Consecutive Days',
    foreign_name: '连续天数',
    value: OVERTIME_TYPES.CONSECUTIVE_DAYS,
    disabled: false,
  },
};

export const DICTIONARY_TYPES = {
  QUICK_NOTES: 'quick_notes',
};

// delivery platform status
export const DELIVERY_PLATFORM_STATUS_ENUMS = {
  INACTIVE: 'inactive',
  ACTIVE: 'active',
};

// audit log event type
export const AUDIT_LOG_EVENT_TYPE = {
  // 菜单发布记录
  PUBLISH_MENU: 'menu_publish',
};
