import React, { useEffect, useMemo } from 'react';
import Head from 'next/head';
import { useSelector, useStore, Provider } from 'react-redux';
import withRedux from 'next-redux-wrapper';
import ConfigProvider from 'antd/lib/config-provider';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import antdEnLocale from 'antd/lib/locale/en_US';
import antdZhLocale from 'antd/lib/locale/zh_CN';
import { RawIntlProvider } from 'react-intl';
import POSModal from '../components/POSModal';
import QRCodesDownloader from '../components/QRCodesDownloader';
import { configureStore } from '../store/redux.js';
import { getLocalItem, KEYS, setLocalItem } from '../store/storage';
import { fetchAppConfig } from '../actions/app';
import { clearUserSession } from '../actions/user';
import { intlSelector, isInAppSelector, localeSelector } from '../selector/app';
import { setStore } from '../utils/request';
import { getAppInfos } from '../utils/utils';
import { bootstrap } from '../utils/bootstrap';
import { HubSpotFormEmbed } from 'src/third_party/HubSpotFormEmbedForm';
import { HubSpotFormEmbedFormV2 } from 'src/third_party/HubSpotFormEmbedFormV2';
import { HubSpot } from 'src/third_party/HubSpot';
import '../utils/overrides';
import './_app.less';

const antdLocales = {
  en: antdEnLocale,
  zh: antdZhLocale,
};
const isServer = typeof window === 'undefined';

const loadPolyfills = async () => {
  if (typeof window.IntersectionObserver === 'undefined') {
    await import('intersection-observer');
  }

  if (typeof window.fetch === 'undefined') {
    await import('whatwg-fetch');
  }
};

if (!isServer) {
  loadPolyfills();
}

const IntlProvider = (props) => {
  const store = useStore();
  const intl = useSelector(intlSelector);
  const locale = useSelector(localeSelector);
  const antdLocale = useMemo(() => antdLocales[locale], [locale]);

  useEffect(() => {
    const appInfo = getAppInfos();
    const version = getLocalItem(KEYS.version);
    if (!appInfo.isApp && version !== process.env.version) {
      setLocalItem({
        [KEYS.version]: process.env.version,
      });
      store.dispatch(clearUserSession());
    }
  }, []);

  return (
    <ConfigProvider locale={antdLocale}>
      <RawIntlProvider value={intl}>{props.children}</RawIntlProvider>
    </ConfigProvider>
  );
};

const DnDProviderWrapper = ({ children }) => {
  const { isInApp } = useSelector(isInAppSelector);

  return <DndProvider backend={isInApp ? TouchBackend : HTML5Backend}>{children}</DndProvider>;
};

const POSApp = (props) => {
  const { Component, pageProps, store } = props;

  useEffect(() => {
    store.dispatch(
      fetchAppConfig(() => {
        bootstrap(store);
      })
    );
    window.addEventListener('unhandledrejection', (event) => {
      event.preventDefault();
    });
  }, []);

  return (
    <React.Fragment>
      <Head>
        <title>POS</title>
        <meta
          name="viewport"
          content="initial-scale=1, width=device-width, minimum-scale=1, maximum-scale=1.0, user-scalable=0"
        />
        <link rel="icon" href={`${process.env.ASSETS_PREFIX}/favicon.png`} type="image/x-icon" />
      </Head>

      <Provider store={store}>
        <IntlProvider>
          <div id="modal-root">
            <POSModal bindTo="modal-root" />
          </div>
          <HubSpot />
          <HubSpotFormEmbed />
          <HubSpotFormEmbedFormV2 />
          <QRCodesDownloader />
          <DnDProviderWrapper>
            <Component {...pageProps} />
          </DnDProviderWrapper>
        </IntlProvider>
      </Provider>
    </React.Fragment>
  );
};

const makeStore = (initialState) => {
  const store = configureStore(initialState);
  setStore(store);
  return store;
};
// withRedux wrapper that passes the store to the App Component
export default withRedux(makeStore, null)(POSApp);
// export default POSApp;
