import { IN_HOUSE_DISTRIBUTION_MODES, CHOWBUS_DISTRIBUTION_MODES } from 'src/consts';
import {
  DISPLAY_COLOR_PREFIX,
  DISCOUNT_EXEMPT_PREFIX,
  MEMBER_PRICE_PREFIX,
  OPEN_PRICE_PREFIX,
  POINT_PRICE_PREFIX,
  PRICE_PREFIX,
  TAX_CODE_PREFIX,
  TAX_EXMPT_PREFIX,
} from 'src/components/MenuManagement/DishForm/const';
import {
  PageBatchUpdateMemberPricing,
  PageBatchUpdatePricing,
  PageBulkDiscountExempt,
  PageBulkTaxExempt,
} from 'src/consts/routes';

export const MENU_ACTIONS_DOM_ID = 'menu-builder-actions';
export const MAX_SELECTION_COUNT = 20;

export const MENU_ACTION_TARGES = {
  TOP: 'top', // 顶级，如全局搜索等
  CONTRACT: 'contract', // 合约相关
  MENU: 'menu', // 菜单相关
  CATEGORY: 'category', // 分类相关
  PAGE: 'page', // 菜单装饰器分页相关
  MEAL: 'meal', // 菜品相关
};

export const MENU_RESOURCE_ACTIONS = {
  DETAIL: 'detail', // 详情
  ADD: 'add', // 新增
  ADD_EXISTING: 'add_existing', // 新增已有
  EDIT: 'edit', // 修改
  SEARCH_MEALS: 'search', // 搜索菜品
  PUBLISH: 'publish', // 发布
};

export const MENU_ACTIONS = {
  EDIT_MENU: 'editMenu',
  UPDATE_MULTIPLE: 'updateMultiple',
  PRICING: 'pricing',
  MEMBER_PRICE: 'memberPrice',
  DISCOUNT: 'discount',
  TAX_SETTINGS: 'taxSettings',
  SORT: 'sort',
  EDIT_DISH: 'editDish',
  CATEGORY_DETAIL: 'detail',
  ADD_EXISTING_DISH: 'addExistingDish',
  ADD_NEW_DISH: 'addNewDish',
  CHANGE_COLOR: 'changeColor',
  SEARCH_MEALS: 'searchMeals',
};
export const MENU_ACTION_LINK = {
  [MENU_ACTIONS.PRICING]: PageBatchUpdatePricing,
  [MENU_ACTIONS.MEMBER_PRICE]: PageBatchUpdateMemberPricing,
  [MENU_ACTIONS.DISCOUNT]: PageBulkDiscountExempt,
  [MENU_ACTIONS.TAX_SETTINGS]: PageBulkTaxExempt,
};

export const MENU_BUILDER_DISTRIBUTION_MODES = [...IN_HOUSE_DISTRIBUTION_MODES, ...CHOWBUS_DISTRIBUTION_MODES];

export const MEAL_INSTANCE_FIELDS = [
  DISPLAY_COLOR_PREFIX,
  PRICE_PREFIX,
  OPEN_PRICE_PREFIX,
  POINT_PRICE_PREFIX,
  MEMBER_PRICE_PREFIX,
  DISCOUNT_EXEMPT_PREFIX,
  TAX_EXMPT_PREFIX,
  TAX_CODE_PREFIX,
];

export const MENU_ROUTE_QUERY = {
  OPEN_MENU_IDS: 'openMenuIds',
  // TODO 当所有餐厅都支持overhaul时移除此query变量
  OPEN_CHANNEL_IDS: 'openContracts',
  PATH: 'path',
};
export const THIRD_PARTY_SOURCE = 'third-party';
export const WEEKDAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export const FIELD_MENU_HOURS = 'menu_hours';
export const FIELD_MENU_HOURS_OVERRIDE = 'menu_hour_overrides';
export const TEMP_ID_PREFIX = 'temp_';
export const SMART_ORDERING_MENU_CHANNELS_VISIBILITY_KEY = 'channel_visibility';
export const ErrorTypes = {
  START_AT_AFTER_END_AT: 'start_at_after_end_at',
  MENU_HOURS_CONFLICT: 'menu_hours_conflict',
};
