import React, { useRef, useEffect, useState, Fragment, useCallback } from 'react';
import {
  ExclamationCircleFilled,
  WarningFilled,
  CheckCircleFilled,
  CloseCircleFilled,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactDOM from 'react-dom';
import styles from './index.less';

/**
 * modalMethods: Methods
 * type Methods = {
 *   show: Function,
 *   hide: Function,
 *   confirm: Function,
 *   showLoading: Function,
 *   hideLoading: Function
 * }
 */
let modalMethods;

const IconMap = {
  info: ExclamationCircleFilled,
  warn: WarningFilled,
  success: CheckCircleFilled,
  error: CloseCircleFilled,
  loading: Loading3QuartersOutlined,
};

const Modal = ({ content, hide, autoCloseTimeout, width = 450, maskClosable = true }) => {
  const ref = useRef();

  const hideModal = useCallback(() => {
    if (!maskClosable) return;
    hide();
    if (autoCloseTimeout && ref.current) {
      clearTimeout(ref.current);
      ref.current = null;
    }
  }, [maskClosable]);

  useEffect(() => {
    if (!content || !autoCloseTimeout) return;

    const time = parseInt(autoCloseTimeout);
    if (!isNaN(time) && time > 0) {
      ref.current = setTimeout(() => {
        hide();
        ref.current = null;
      }, autoCloseTimeout);
    }

    return () => {
      if (ref.current) clearTimeout(ref.current);
    };
  }, [content, autoCloseTimeout]);

  if (!content) return null;

  return (
    <div className={styles['pos-modal-root']}>
      <div className="pos-modal-mask" onClick={hideModal} />
      <div className="pos-modal-content" style={{ width }}>
        {content}
      </div>
    </div>
  );
};

const POSModal = ({ bindTo }) => {
  const ref = useRef(null);
  const { formatMessage } = useIntl();
  const [modalContent, setModalContent] = useState(undefined);
  const [autoCloseTimeout, setAutoCloseTimeout] = useState(undefined);
  const [modalWidth, setModalWidth] = useState(450);
  const [maskClosable, setMaskClosable] = useState(true);

  useEffect(() => {
    let modalContainer;
    if (bindTo) {
      modalContainer = document.getElementById(bindTo) || document.body;
    } else {
      modalContainer = document.body;
    }
    ref.current = document.createElement('div');
    modalContainer.appendChild(ref.current);

    return () => {
      modalContainer.removeChild(ref.current);
    };
  }, []);

  const hide = (e) => {
    // 防止点击穿透
    if (e instanceof Event) e.stopPropagation();
    setModalContent(null);
  };

  const show = ({
    messageId,
    values = {},
    description,
    content,
    type = 'warn',
    autoCloseTimeout,
    width,
    maskClosable = true,
    okButtonText = '',
    onOK,
  }) => {
    let title;
    if (messageId) {
      title = <FormattedMessage id={messageId} values={values} />;
    } else {
      title = content;
    }
    const handleOK = (e) => {
      e.preventDefault();
      e.stopPropagation();
      hide();
      typeof onOK === 'function' && onOK();
    };
    const modalContent = (
      <Fragment>
        <div className="pos-modal-header">
          <div className={`pos-modal-icon ${type}`}>
            {React.createElement(IconMap[type], { spin: type === 'loading' })}
          </div>
        </div>
        <div className="pos-modal-body">
          <div className="title">{title}</div>
          {!!description && <div className="description">{description}</div>}
          {okButtonText && (
            <Button
              block
              type="primary"
              size="large"
              shape="round"
              style={{ margin: '30px 0 15px' }}
              onClick={handleOK}
            >
              {okButtonText}
            </Button>
          )}
        </div>
      </Fragment>
    );
    if (width) setModalWidth(width);
    setModalContent(modalContent);
    setAutoCloseTimeout(autoCloseTimeout);
    setMaskClosable(maskClosable);
  };

  const showLoading = ({ messageId = 'fetching-data', maskClosable = false } = {}) => {
    show({ messageId, type: 'loading', maskClosable });
  };

  const hideLoading = () => {
    hide();
  };

  const showConfirmModal = ({
    messageId,
    values,
    content,
    description,
    extraMessage = '',
    onConfirm,
    onCancel,
    type = 'warn',
    width,
    maskClosable = true,
    confirmText = '',
    cancelText = '',
    showUndoButton = false,
    showCancelButton = true,
    extraMessageContainerClassName = '',
  }) => {
    let text;

    if (messageId) {
      text = formatMessage({ id: messageId }, values);
    } else {
      text = content;
    }
    text = (text || '').replace(/\n/g, '<br />');

    const handleConfirm = (e) => {
      e.preventDefault();
      e.stopPropagation();
      hide();
      typeof onConfirm === 'function' && onConfirm();
    };

    const handleCancel = (e) => {
      e.preventDefault();
      e.stopPropagation();
      hide();
      typeof onCancel === 'function' && onCancel();
    };

    const modalContent = (
      <Fragment>
        <div className="pos-modal-header">
          <div className={`pos-modal-icon ${type}`}>{React.createElement(IconMap[type])}</div>
        </div>
        <div className="pos-modal-body">
          <div className="bold title" dangerouslySetInnerHTML={{ __html: text }} />
          {!!description && <div className="description">{description}</div>}
          {extraMessage && (
            <div className={`confirm-detail-text ${extraMessageContainerClassName}`}>{extraMessage}</div>
          )}
          <Button block type="primary" size="large" shape="round" style={{ marginTop: 30 }} onClick={handleConfirm}>
            {confirmText || <FormattedMessage id="action.confirm" />}
          </Button>
          {showCancelButton && (
            <Button block size="large" shape="round" onClick={handleCancel} style={{ marginTop: 15 }}>
              {cancelText || <FormattedMessage id="action.cancel" />}
            </Button>
          )}
          {showUndoButton && (
            <Button block size="large" shape="round" type="text" onClick={hide} style={{ marginTop: 15 }}>
              <FormattedMessage id="action.cancel" />
            </Button>
          )}
        </div>
      </Fragment>
    );

    if (width) setModalWidth(width);
    setModalContent(modalContent);
    setAutoCloseTimeout(undefined);
    setMaskClosable(maskClosable);
  };

  /**
   * 确认提示对话框
   * @param {Object} params
   * @param {String} params.messageId 确认提示框标题国际化id
   * @param {Object} params.values 国际化标题时需要的更多参数 optional
   * @param {React.Element} params.extraMessage 二级标题 optional
   * @param {React.Element} params.content 确认提示框内容
   * @param {String} params.type 'warn'/'info'/'warning', 默认值为‘wran’
   * @param {Number} params.width 提示框宽度
   * @param {Boolean} params.maskClosable 点击蒙层是否关闭对话框 默认值为true
   * @param {React.Element} params.confirmText 确认按钮文本，默认为confirm/确认
   * @param {React.Element} params.cancelText 取消按钮文本，默认为cancel/取消
   * @param {Boolean} params.showUndoButton 是否显示撤销按钮, 默认为false
   * @param {Boolean} params.showCancelButton 是否显示取消按钮，默认为true
   * @param {String} params.extraMessageContainerClassName 额外信息容器样式类名，默认为空字符串
   * @returns React.FC
   */
  const confirm = ({
    messageId,
    values,
    description,
    extraMessage,
    content,
    type = 'warn',
    width,
    maskClosable = true,
    confirmText = '',
    cancelText = '',
    showUndoButton,
    showCancelButton = true,
    extraMessageContainerClassName = '',
  }) => {
    return new Promise((resolve, reject) => {
      showConfirmModal({
        messageId,
        values,
        content,
        description,
        extraMessage,
        type,
        onConfirm: resolve,
        onCancel: reject,
        width,
        maskClosable,
        confirmText,
        cancelText,
        showUndoButton,
        showCancelButton,
        extraMessageContainerClassName,
      });
    });
  };

  modalMethods = {
    show,
    hide,
    confirm,
    showLoading,
    hideLoading,
  };

  if (!ref.current) return null;
  return ReactDOM.createPortal(
    <Modal
      content={modalContent}
      maskClosable={maskClosable}
      autoCloseTimeout={autoCloseTimeout}
      hide={hide}
      width={modalWidth}
    />,
    ref.current
  );
};

export { modalMethods };

export default POSModal;
