export default {
  'serviceSettings.title': 'Workflow',
  'serviceSettings.tabs.general': 'General',
  'serviceSettings.tabs.shopping-cart': 'Shopping Cart',
  'serviceSettings.tabs.payment': 'Payment',
  'serviceSettings.tabs.delivery': 'Delivery',
  'serviceSettings.tabs.pickup-served-notification': 'Pickup/Served Notification',

  'serviceSettings.section.floorPlan': 'Floor Plan',
  'serviceSettings.section.shoppingCart': 'Shopping Cart',
  'serviceSettings.section.send': 'Send',
  'serviceSettings.section.pay': 'Pay',
  'serviceSettings.section.clockInAndOut': 'Employee Clock In/Out',
  'serviceSettings.section.offlineMode': 'Offline Mode',
  'serviceSettings.floorPlan': 'Floor Plan',
  'serviceSettings.floorPlan.helpText': '(recommended for Full Service Restaurants)',
  'serviceSettings.floorPlan.description': 'Dine In orders will be assigned to tables',
  'serviceSettings.floorPlan.defaultViewingMode': 'Default viewing mode',
  'serviceSettings.floorPlan.turnOnClearningStatus': 'Turn on cleaning status',
  'serviceSettings.floorPlan.turnOnClearningStatus.description':
    'User need to confirm the table is ready after the bill is paid for it becomes available. Before confirmation table will display cleaning status',
  'serviceSettings.floorPlan.startFlashingDurationThreshold': 'Start flashing when duration reaches',
  'serviceSettings.floorPlan.turnOnCheckOverview': 'Turn on Check Overview',
  'serviceSettings.floorPlan.turnOnCheckOverview.description': 'Check Overview will display for all occupied tables.',
  'serviceSettings.floorPlan.displayFullName': 'Display the full name(instead of the initials)',
  'serviceSettings.quickPay': 'Send & Pay',
  'serviceSettings.quickPay.helpText': '(recommended for Quick Service Restaurants)',
  'serviceSettings.quickPay.description': 'Sending & payment actions will be combined for quick pay',
  'serviceSettings.promptForMemberIDEachTimeAfterOpeningATable':
    'Prompt for Member ID each time after opening a table.',
  'serviceSettings.askCustomerInfoForCallInOrders': 'Ask customer info for Call In orders',
  'serviceSettings.askCustomerInfoForCallInOrders.description':
    'Customer information will be prompted for Call In order when this option is on',
  'serviceSettings.backToHomeAfterSend': 'Go to previous page after send',
  'serviceSettings.backToHomeAfterSend.description':
    'Go back to the previous page after sending items to kitchen when this option is on',
  'serviceSettings.manuallyPrintingTheBillGoToThePreviousPage':
    'Go to the previous page after manually printing the bill in the shopping cart.',
  'serviceSettings.goToTheFloorPlanOrShoppingCartAfterEnteringThePIN':
    'Go to the floor plan or shopping cart after entering the PIN',
  'serviceSettings.goToTheFloorPlanOrShoppingCartAfterEnteringThePIN.description':
    'Go to the floor plan or shopping cart after entering the PIN when this option is on',
  'serviceSettings.goToTheFloorPlanOrShoppingCartAfterEnteringThePIN.orderSourceTitle': 'Select a default order source',
  'serviceSettings.voidAuthorization': 'Require authorization for voiding dishes',
  'serviceSettings.supportEmailAndTextReceipt': 'Ask customer for receipt options',
  'serviceSettings.displaySendBatches': 'Display send batches',
  'serviceSettings.consolidateIdenticalDishesAfterSend': 'Consolidate identical dishes after send',
  'serviceSettings.supportEmailAndTextReceipt.description':
    'Receipt options, such as email, text or print receipt will be prompted for card payments when this option is on',
  'serviceSettings.shoppingCartActionShortcuts': 'Shopping Cart Action Shortcuts (Select up to 3)',
  'serviceSettings.enterCentsFirst': 'Enter cents first (from right to left)',
  'serviceSettings.delaySendToKitchen': 'Delay send to kitchen',
  'serviceSettings.delaySendToKitchen.terminal.title': 'Choose a POS Terminal （Required）',
  'serviceSettings.delaySendToKitchen.terminal.description':
    'Note: All delayed-sending orders must be assigned to a POS terminal which is required to attached to a kitchen printer.',
  'serviceSettings.enableGuestMode': 'Enable Guest Mode',
  'serviceSettings.displayDishImage': 'Display dish image',
  'serviceSettings.enableCustomizationLimitProperties': 'Apply customization limit properties',
  'serviceSettings.enableCustomizationLimitProperties.description':
    'Customization will be validated and limited by maximum selections, maximum quantities, and maximum quantities per option.',
  'serviceSettings.autoConfirmAndSend.paid': 'Paid',
  'serviceSettings.autoConfirmAndSend.unpaid': 'Unpaid',
  'serviceSettings.autoConfirmAndSend.title': 'Automatically confirm orders and send to kitchen',
  'serviceSettings.autoConfirmAndSend.kiosk.title': 'Auto Confirm and Send Paid Kiosk Orders',
  'serviceSettings.autoConfirmAndSend.QRCode.title': 'Auto Confirm and Send Paid QR Code Orders',
  'serviceSettings.autoConfirmAndSend.terminal.title': 'Choose a POS Terminal （Required）',
  'serviceSettings.autoConfirmAndSend.kiosk.autoFinish':
    'Would you like to notify the customer when the Kiosk order is ready?',
  'serviceSettings.autoConfirmAndSend.QRCode.autoFinish':
    'Would you like to notify the customer when the QR Code order is ready?',

  'serviceSettings.autoConfirmAndSend.QRCode.autoToEmployee':
    'Automatically transfer QR Code orders that confirmed automatically to employee:',
  'serviceSettings.autoConfirmAndSend.kiosk.autoToEmployee':
    'Automatically transfer kiosk orders that confirmed automatically to employee:',
  'serviceSettings.autoConfirmAndSend.deliverectordering.chowbusOnlineOrderingAutoToEmployee':
    'Automatically transfer Chowbus Online Ordering orders that confirmed automatically to employee:',
  'serviceSettings.autoConfirmAndSend.deliverectordering.thirdPartyOnlineOrdersAutoToEmployee':
    'Automatically transfer third party online orders that confirmed automatically to employee:',
  'serviceSettings.autoConfirmAndSend.autoToEmployee.description':
    'Doing so will allocate the sales and tips of the order to this employee.',
  'serviceSettings.autoConfirmAndSend.autoToEmployee.placeholder': 'select a employee',

  'serviceSettings.autoConfirmAndSend.autoFinish': 'Would you like to notify the customer when the order is ready?',
  'serviceSettings.autoConfirmAndSend.autoFinish.description':
    'Pickup/Served Notification settings are available for POS App version v3.25.0 and later. Go to Pickup/Served Notification tab on this page to set.',
  'serviceSettings.autoConfirmAndSend.autoFinish.yes': 'Yes',
  'serviceSettings.autoConfirmAndSend.autoFinish.yes.description':
    'You can notify the customer in Unfinished Orders section',
  'serviceSettings.autoConfirmAndSend.autoFinish.no': 'No',
  'serviceSettings.autoConfirmAndSend.terminal':
    'Note: All auto-confirm orders must be assigned to a POS terminal. We recommend choosing your main POS terminal.',
  'serviceSettings.autoConfirmAndSend.terminal.serialNo': 'Serial Number: ',
  'serviceSettings.showAggregatedCashFlow': 'Display an aggregated view for all cash flows',
  'serviceSettings.countChangeAsTips': 'Count change as tips',
  'serviceSettings.requireClockInAndOut': 'Require employees to clock in and clock out',
  'serviceSettings.requireRecordBreaks': 'Require employees to record break times',
  'serviceSettings.autoClockOutAtEndOfBusinessDay': 'Automatically clock employees out at the end of business day',
  'serviceSettings.autoClockOutAtEndOfBusinessDay.description':
    'System will use Closeout Report Cutoff Time for any employees who forget to clock out.',
  'serviceSettings.automaticallyLabelDishes': 'Automatically label dishes',
  'serviceSettings.automaticallyLabelDishes.description':
    'All dishes will be labelled based on the sequence it was added to the shopping cart',
  'serviceSettings.automaticallyLabelDishes.printouts.title':
    'Select printouts you would like the label to be displayed',
  'serviceSettings.whenToAutoPopUpCustomizationPanel.title':
    'When should the customization panel automatically pop up?',
  'serviceSettings.whenToAutoPopUpCustomizationPanel.option.required':
    'Only if a dish <span class="bold">requires</span> customization selections',
  'serviceSettings.whenToAutoPopUpCustomizationPanel.option.exists': 'If a dish has customization options',
  'serviceSettings.sortCustomizationsAsTheOrderDisplayed':
    'Sort customizations in the order displayed in the shoppint cart',
  'serviceSettings.sortCustomizationsAsTheOrderDisplayed.description':
    'When the switch is turned off, the customizations will be displayed in the order you added them.',
  'serviceSettings.enableOpenTab': 'Enable open tabs',
  'serviceSettings.unableToToogleEnableOpenTabs':
    'Unable to turn off “Enable open tabs” before all open tabs orders is closed.',
  'serviceSettings.enableTableService.prompt':
    'When the switch is turned off, the floor plan cannot be viewd on POS App. Sure to continue?',

  'serviceSettings.autoConfirmAndSend.deliverectordering.autoFinish':
    'Would you like to notify the customer when the online order is ready?',

  'serviceSettings.autoConfirmAndSend.deliverectordering.autoFinish.finish_duration.02':
    'minutes later, auto finish order ',
  'serviceSettings.autoConfirmAndSend.deliverectordering.autoFinish.finish_duration':
    ' (This setting does not affect the third-party platforms’ preparation time.) ',
  'serviceSettings.offlineMode.reportsFirst':
    "If the network is restored and offline mode is ended, the priority will be to ensure that the restaurant's accounts are accurate, rather than ensuring that no dishes are lost.",
  'serviceSettings.offlineMode.reportsFirst.tips':
    "When the switch is turned off, we will retain as much dish information as possible to ensure the restaurant doesn't lose any dishes. Whether this switch is on or off will not affect the restaurant's ability to receive payments; it only applies to certain exception handling scenarios. It is recommended to keep the switch on.",
  'serviceSettings.offlineMode.reportsFirst.note':
    "Note: When the restaurant's network is disconnected, the device will automatically enter offline mode after 30 seconds. Under offline mode, you can use the POS system as usual (for ordering, sending to kitchen, printing, and payment).",
  'serviceSettings.offlineMode.reportsFirst.caution':
    'Caution: It is advisable to operate the entire store with one device to avoid payment or account discrepancies when multiple devices are used to manage a single order.',
  'serviceSettings.actionsEnabledAuthorizationKeypad':
    'Enable authorization keypad for the following actions when user does not have permission',
  'serviceSettings.enablePartialPaymentByCreditCard': 'Enable partial payment by card',
  'serviceSettings.enablePartialPaymentByCreditCard.recommend':
    'Recommend dine-in restaurants with more split orders to use',
  'serviceSettings.enablePartialPaymentByCreditCard.description':
    'Supported partial payment when paying by card, without having to split by amount.',

  'serviceSettings.quickInputForPhoneNumber': 'Quick Input for Phone Number（3 digits）',
  'serviceSettings.disappearingCallLogMode': 'Disappearing call log mode（Using Caller ID）',
  'serviceSettings.autoDelete': 'Auto-delete（after clicking to view）',
  'serviceSettings.swipeLeftToDeleteManually': 'Swipe left to delete manually',
  'serviceSettings.delivery.callInOrders': 'Call In orders',
  'serviceSettings.delivery.DriverName': 'Driver',
  'serviceSettings.delivery.useYourOwnDeliveryDrivers': 'Use your own delivery drivers',
  'serviceSettings.delivery.maximumDeliveryRadius': 'Maximum Delivery Radius',
  'serviceSettings.delivery.maximumDeliveryRadiusUnit': 'miles',
  'serviceSettings.delivery.driveryFree': 'Delivery Fee',
  'serviceSettings.delivery.configureDeliveryServiceFees': 'Configure delivery service fees',
  'serviceSettings.delivery.customDeliveryFeeTaxable': 'Custom Delivery Fee: Taxable?',
  'serviceSettings.delivery.customDeliveryFeeTaxable.yes': 'Yes',
  'serviceSettings.delivery.customDeliveryFeeTaxable.no': 'No',

  'serviceSettings.section.toolsBar.title': 'Tools Bar',
  'serviceSettings.section.toolsBar.column.icon': 'Icon',
  'serviceSettings.section.toolsBar.column.function': 'Function',
  'serviceSettings.section.toolsBar.column.visible': 'Visibility',

  'serviceSettings.section.pickup-served-notification.tips':
    'Pickup/Served Notification settings are available for POS App version v3.25.0 and later.',
  'serviceSettings.section.pickupNotificationSettings': 'Pickup Notification Settings',
  'serviceSettings.section.thirdPartyPlatformNotificationSettings': 'Third-party Notification Settings',
  'serviceSettings.section.thirdPartyPlatformNotificationSettings.tips':
    'The third-party platform will notify according to its rules.',
  'serviceSettings.section.notificationMethod': 'Notification Method',
  'serviceSettings.section.notificationMethod.sms': 'SMS',
  'serviceSettings.section.notificationMethod.sms.description':
    'Send a pickup notification SMS to customers who have logged in or left their phone numbers.',
  'serviceSettings.section.cps': 'Customer Pickup Screen',
  'serviceSettings.section.cps.description':
    'Ticket number will be displayed on the screen when orders have been served or in preparation.',
  'serviceSettings.section.cps.link': 'Setting',
  'serviceSettings.section.notificationScope': 'Notification Scope',
  'serviceSettings.section.notificationScope.orderSource': 'Order Source',
  'serviceSettings.section.notificationScope.orderSource.walkIn': 'Walk In',
  'serviceSettings.section.notificationScope.orderSource.callIn': 'Call In',
  'serviceSettings.section.notificationScope.orderSource.qrCode': 'QR Code',
  'serviceSettings.section.notificationScope.orderSource.kiosk': 'Kiosk',
  'serviceSettings.section.notificationScope.orderSource.online': 'Online',
  'serviceSettings.section.notificationScope.orderSource.website': '(Website)',
  'serviceSettings.section.notificationScope.orderSource.thirdPartyPlatform': '(Third-party)',
  'serviceSettings.section.notificationScope.serviceType': 'Service Type',
  'serviceSettings.section.notificationScope.serviceType.dineIn': 'Dine In',
  'serviceSettings.section.notificationScope.serviceType.takeOut': 'Take Out',
  'serviceSettings.section.notificationScope.serviceType.delivery': 'Delivery',
  'serviceSettings.section.notificationScope.sendingNotificationMethod': 'How to send notifications',
  'serviceSettings.section.notificationScope.sendingNotificationMethod.manualNotification': 'Manual Notification',
  'serviceSettings.section.notificationScope.sendingNotificationMethod.manualNotification.tips':
    'Manual Notification: In the unfinished orders page of the POS App, manually click the Ready button.',
  'serviceSettings.section.notificationScope.sendingNotificationMethod.automaticNotification': 'Automatic Notification',
  'serviceSettings.section.notificationScope.sendingNotificationMethod.automaticNotification.suffix':
    'minutes later, notify for pick-up automatically',
};
