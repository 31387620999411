const env = process.env.STAGING_ENV || 'staging-02';
export const pushAppKeys = {
  'staging-01': {
    key: 'dd23c571d68777847d2a',
    cluster: 'us2',
  },
  'staging-02': {
    key: '4511753e1c6ccb8c4a13',
    cluster: 'us2',
  },
  'staging-03': {
    key: '856cc095e2f2966fa8ff',
    cluster: 'ap1',
  },
  production: {
    key: '01ca590969e67eb01faf',
    cluster: 'us2',
  },
}[env];

export const cdsPushAppKeys = {
  'staging-01': {
    key: 'dd23c571d68777847d2a',
    cluster: 'us2',
  },
  'staging-02': {
    key: 'dd23c571d68777847d2a',
    cluster: 'us2',
  },
  'staging-03': {
    key: 'dd23c571d68777847d2a',
    cluster: 'ap1',
  },
  production: {
    key: '01ca590969e67eb01faf',
    cluster: 'us2',
  },
}[env];

export const mixpanelToken = process.env.MIXPANEL_TOKEN;
export const ADMIN_AUTH_CLIENT_ID = process.env.ADMIN_AUTH_CLIENT_ID;
export const CPS_AUTH_CLIENT_ID = process.env.CPS_AUTH_CLIENT_ID;
export const POS_AUTH_CLIENT_ID = process.env.POS_AUTH_CLIENT_ID;
export const qrCodeServiceDomain = {
  demo: 'https://qrcode-staging-01.chowbus.com/',
  'staging-01': 'https://qrcode-staging-01.chowbus.com/',
  'staging-02': 'https://qrcode-staging-02.chowbus.com/',
  'staging-03': 'https://qrcode-staging-03.chowbus.com/',
  production: 'https://qrcode.chowbus.com/',
}[env];
export const BILLING_SETTLEMENT_PREFIX = {
  'staging-01': 'https://billing-settlement-staging-01.chowbus.com',
  'staging-02': 'https://billing-settlement-staging-02.chowbus.com',
  'staging-03': 'https://billing-settlement-staging-03.chowbus.com',
  production: 'https://billing-settlement.chowbus.com',
}[env];
export const BILLING_REPORT_API_PREFIX = {
  'staging-01': 'https://billing-report-staging-01.chowbus.com',
  'staging-02': 'https://billing-report-staging-02.chowbus.com',
  'staging-03': 'https://billing-report-staging-03.chowbus.com',
  production: 'https://billing-report.chowbus.com',
}[env];
export const BILLING_PAYMENT_API_PREFIX = {
  'staging-01': 'https://payment-api-staging-01.chowbus.com',
  'staging-02': 'https://payment-api-staging-02.chowbus.com',
  'staging-03': 'https://payment-api-staging-03.chowbus.com',
  production: 'https://payment-api.chowbus.com',
}[env];
export const ONLINE_ORDERING_API_PREFIX = {
  'staging-01': 'https://api-staging-01.chowbus.com/online-ordering',
  'staging-02': 'https://api-staging-02.chowbus.com/online-ordering',
  'staging-03': 'https://api-staging-03.chowbus.com/online-ordering',
  production: 'https://api.chowbus.com/online-ordering',
}[env];
export const SMS_SERVICE_API_PREFIX = {
  'staging-01': 'https://api-staging-01.chowbus.com/sms-service',
  'staging-02': 'https://api-staging-02.chowbus.com/sms-service',
  'staging-03': 'https://api-staging-03.chowbus.com/sms-service',
  production: 'https://api.chowbus.com/sms-service',
}[env];
export const CATALOG_SERVICE_API_PREFIX = {
  'staging-01': 'https://api-staging-01.chowbus.com/compendium',
  'staging-02': 'https://api-staging-02.chowbus.com/compendium',
  'staging-03': 'https://api-staging-03.chowbus.com/compendium',
  production: 'https://api.chowbus.com/compendium',
}[env];
export const SENTRY_DSN = process.env.SENTRY_DSN;
export const BASIC_AUTH = process.env.BASIC_AUTH || '';
export const POS_CUSTOMER = 'POS Customer';
export const POS_VISITOR = 'POS Visitor';
export const TABLET_ORDERING_WEBPAGE_DOMAIN = {
  'staging-01': 'https://qrcode-tablet-staging-01.chowbus.com',
  'staging-02': 'https://qrcode-tablet-staging-02.chowbus.com',
  'staging-03': 'https://qrcode-tablet-staging-03.chowbus.com',
  production: 'https://qrcode-tablet.chowbus.com',
}[env];
export const BILLING_REPORT_BASIC_AUTH = process.env.BILLING_REPORT_BASIC_AUTH || '';
